import { DatePicker, Dropdown, Icon, Menu, Popover, Radio, Button } from "antd";
import moment from "moment";
import React, { useState } from "react";
import "moment/locale/pt-br";

import locale from "~/components/UI/DatePicker/Locale";
import {
  ButtonsWrapper,
  Container,
  Content,
  ExtensionButton,
  RegistrationButton,
} from "./styles";
import { LeadSummary } from "./LeadSummary";
import { ModuleHeader } from '~/components/ModuleHeader';
import { ChromeIcon } from '~/icons/ChromeIcon';
import AdvancedFilter from '~/components/AdvancedFilter';

import { downloadExcel } from "../../LeadsTable/downloadExcel";

const { MonthPicker } = DatePicker;

export const Header = ({
  displayMode,
  setDisplayMode,
  date,
  setDate,
  showDrawer,
  setIsMetricsModalVisible,
  currentSummary,
  fetchQtddByMonth,
  isLeadsDrawerVisible,
  isSixthOnboardingStepActive,
  isSeventhOnboardingStepActive,
  finishCurrentOnboardingStep,
  onboardingStep,
  user,
  selectedRowsKeys,
  selectedRows,
  columns,
  setPeriod,
  period,
}) => {

  return (
    <Container>
      <ModuleHeader 
        breadcrumbs={['Leads']} 
        actions={
          <>
            {displayMode === "funnel" && (
              <MonthPicker
                defaultValue={moment(date, "MM/YYYY")}
                format={"MM/YYYY"}
                locale={locale}
                onChange={(value) => {
                  const formattedDate = value.format("MM-YYYY");
                  setDate(formattedDate);
                  fetchQtddByMonth(formattedDate);
                }}
                allowClear={false}
                disabledDate={(current) => current > moment()}
              />
            )}
            <Radio.Group
              value={displayMode}
              onChange={(event) => setDisplayMode(event.target.value)}
            >
              <Radio.Button value="table">
                <Icon type="table" />
              </Radio.Button>
              <Radio.Button value="funnel">
                <Icon type="funnel-plot" />
              </Radio.Button>
              <Radio.Button value="calendar">
                <Icon type="calendar" />
              </Radio.Button>
            </Radio.Group>
            <Popover
              placement="left"
              content="Clique aqui para baixar a extensão"
              visible={isSixthOnboardingStepActive}
            >
              <ExtensionButton
                style={{ zIndex: isSixthOnboardingStepActive ? 999 : "auto" }}
                type="dashed"
                href="https://chrome.google.com/webstore/detail/contlabs-para-whatsapp/ggpdkeoppcdhhoidbjimchgpbeagikmb?hl=en"
                target="_blank"
                onClick={() =>
                  finishCurrentOnboardingStep({
                    isActive: isSixthOnboardingStepActive,
                    step: onboardingStep,
                    user
                  })
                }
              >
                <ChromeIcon />
                Baixar extensão
              </ExtensionButton>
            </Popover>
            {selectedRowsKeys.length > 0 && displayMode === 'table' && (
              <Button type="primary" onClick={() => downloadExcel({ selectedRows, columns })}>
                <Icon type="download" /> Excel
              </Button>
            )}
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={() => showDrawer()}>
                    Novo lead
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    disabled={isSeventhOnboardingStepActive}
                    onClick={() => setIsMetricsModalVisible(true)}
                  >
                    Cadastrar métricas
                  </Menu.Item>
                </Menu>
              }
            >
              <Popover
                placement="left"
                content="Clique aqui e crie um Lead"
                visible={isSeventhOnboardingStepActive && !isLeadsDrawerVisible}
              >
                <RegistrationButton
                  type="primary"
                  style={{ zIndex: isSeventhOnboardingStepActive ? 999 : "auto" }}
                >
                  Cadastro <Icon type="down" />
                </RegistrationButton>
              </Popover>
            </Dropdown>
          </>
        }
      />

    {displayMode === "table" && (
      <AdvancedFilter 
        style={{marginTop: '10px'}} 
        onSubmit={setPeriod}
      />) }

      {displayMode === "funnel" && <LeadSummary data={currentSummary} />}
    </Container>
  );
};

import { createAsyncActions } from '~/utils/store';

export const SHOW = '@@DRAWER_LEADS/SHOW';
export const CLOSE = '@@DRAWER_LEADS/CLOSE';

export const CREATE = createAsyncActions('@@DRAWER_LEADS/CREATE');
export const SAVE = createAsyncActions('@@DRAWER_LEADS/SAVE');
export const DELETE = createAsyncActions('@@DRAWER_LEADS/DELETE');

export const show = (data = null) => ({
    type: SHOW,
    payload: {
        data
    }
});

export const close = () => ({
    type: CLOSE,
    payload: {}
});

export const create = (data) => ({
    type: CREATE.INIT,
    payload: {
        request:{
            method: 'post',
            url:'/leads',
            data,
            showLoading: false
        }
      }
});

export const save = (id, data) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'post',
            url:`/leads/${id}`,
            data,
            showLoading: false
        }
    }
});

export const updateReview = (id, is_revised) => ({
    type: SAVE.INIT,
    payload: {
        request:{
            method: 'put',
            url:`/leads/${id}/review`,
            data: {is_revised},
            showLoading: false
        }
    }
});

export const remove = (lead) => ({
    type: DELETE.INIT,
    payload: {
        request:{
            method: 'delete',
            url:`/leads/${lead.id}`
        },
        lead
    }
});
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dropdown,
  Menu,
  Card,
  Button,
  Input,
  Row,
  Col,
  Statistic,
  Modal,
  message,
  Badge,
  Tooltip,
} from "antd";
import moment from "moment";
import fileDownload from "js-file-download";

import "moment/locale/pt-br";

import { toBRL } from "../../../components/UI/InputCurrency";
import DownloadBatch from "../../../components/Components/DownloadBatch";
import { request } from "../../../components/Instance";
import * as montlyPaymentActions from "~/screens/Financial/MonthlyPayment/actions";
import { finishCurrentOnboardingStepAction } from "../../../components/Onboarding/helpers";
import {
  ModuleHeader,
  ModuleHeaderWrapper,
  HeaderLeftContent,
  HeaderRightContent,
} from "~/components/ModuleHeader";
import DrawerMonthlyPayment from "~/containers/DrawerMonthlyPayment";
import TableList from "./Components/TableList";
import PulseBadge from "~/components/PulseBadge";

import DrawerFilter from "./Components/DrawerFilter";

const { Search } = Input;

const MonthlyPaymentList = () => {
  const [search, setSearch] = useState("");
  const [onlyNotReajusted, setOnlyNotReajusted] = useState(false);
  const [drawerPay, setDrawerPay] = useState(null);
  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const dispatch = useDispatch();
  const { data, user, filter, isLoading, customersById } = useSelector(
    (state) => ({
      data: state.monthlyPayment.data,
      user: state.user,
      filter: state.monthlyPayment.filter,
      isLoading: state.monthlyPayment.isLoading,
      customersById: state.customersById,
    })
  );

  const { isOnboardingActive, onboardingStep, isVideoModalVisible } =
    useSelector((state) => state.onboarding);

  const onAction = (e, data) => {
    switch (e.key) {
      case "pay":
        setDrawerPay(data);
        break;
      case "inadimplente":
        Modal.confirm({
          title: "Você realmente deseja marcar como inadimplente?",
          content: "Você poderá alterar isso mais tarde.",
          okText: "Sim",
          okType: "danger",
          cancelText: "Cancelar",
          onOk: () => dispatch(montlyPaymentActions.markAsDefaulting(data.id)),
        });
        break;
      case "ajuizado":
        Modal.confirm({
          title: "Você realmente deseja marcar como ajuizado?",
          content: "Você poderá alterar isso mais tarde.",
          okText: "Sim",
          okType: "danger",
          cancelText: "Cancelar",
          onOk: () => dispatch(montlyPaymentActions.markAsProcessed(data.id)),
        });
        break;
      case "delete":
        Modal.confirm({
          title: "Você realmente deseja excluir esta menalidade?",
          content:
            "Mensalidades excluídas são geradas automaticamente após gerar novamente as mensalidades mensais.",
          okText: "Sim",
          okType: "danger",
          cancelText: "Cancelar",
          onOk: () => dispatch(montlyPaymentActions.deleteHonorary(data.id)),
        });
        break;
    }
  };

  const changeAmount = (key, value) => {
    setDrawerPay((prevState) => ({
      ...prevState,
      amount: value,
    }));
    calculateDiff();
  };

  const changePaid = (key, value) => {
    setDrawerPay((prevState) => ({
      ...prevState,
      paid: value,
    }));
    calculateDiff();
  };

  const calculateDiff = () => {
    let diff = drawerPay.paid > 0 ? drawerPay.paid - drawerPay.amount : 0;

    setDrawerPay((prevState) => ({
      ...prevState,
      interest: diff > 0 ? diff : 0,
      discount: diff < 0 ? diff : 0,
    }));
  };

  const handleGenerate = async (needReajust) => {
    async function generate() {
      try {
        setIsGenerateLoading(true);

        await dispatch(
          montlyPaymentActions.monthlyGenerate({
            from: filter.from,
            to: filter.to,
          })
        );

        // dispatch(finishCurrentOnboardingStepAction({
        //   isActive: checkIfStepIsActive(11),
        //   step: onboardingStep,
        //   user,
        // }));

        dispatch(montlyPaymentActions.loadPayments(filter));
      } finally {
        setIsGenerateLoading(false);
      }
    }

    if (needReajust) {
      Modal.confirm({
        title: "Existem mensalidades que precisam ser reajustadas",
        content: "Deseja continuar mesmo assim?",
        okText: "Sim",
        okType: "primary",
        cancelText: "Não",
        onOk: async () => {
          await generate();
        },
      });
    } else {
      await generate();
    }
  };

  const selectRow = (record) => {
    const newSelectedRowKeys = selectedRowKeys.includes(record.key)
      ? selectedRowKeys.filter((key) => key !== record.key)
      : [...selectedRowKeys, record.key];
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleExportToExcel = async () => {
    try {
      const response = await request(
        "POST",
        `/monthly/export/excel`,
        {
          ...filter,
          ids: selectedRowKeys,
        },
        true,
        {
          responseType: "blob",
        }
      );

      const formattedFrom = moment(filter.from).format("DD/MM/YYYY");
      const formattedTo = moment(filter.to).format("DD/MM/YYYY");

      fileDownload(
        response.data,
        `Mensalidades_${formattedFrom}_${formattedTo}.xlsx`
      );
    } catch (error) {
      message.error("Não foi possível realizar a exportação!");
    }
  };

  const checkIfStepIsActive = (step) => {
    return (
      !isVideoModalVisible && isOnboardingActive && onboardingStep === step
    );
  };

  const sum = (array, key) => {
    return array.reduce((a, b) => a + (b[key] || 0), 0);
  };

  const show13 = () => {
    if (sum(data, "amount13") > 0) return true;

    const decimoTerceiroSplit = user.tenant.decimo_terceiro_split;
    const fromMonth = moment(filter.from).format("MM");
    const toMonth = moment(filter.to).format("MM");
    const filterBy = filter.filter_by;

    const rules = {
      1: { due_at: "12", apuration_at: "11" },
      2: { due_at: ["11", "12"], apuration_at: ["10", "11"] },
      3: { due_at: ["10", "11", "12"], apuration_at: ["09", "10", "11"] },
    };

    return (
      rules[decimoTerceiroSplit][filterBy].includes(fromMonth) ||
      rules[decimoTerceiroSplit][filterBy].includes(toMonth)
    );
  };

  let filtered = Array.isArray(data)
    ? data.filter(function (str) {
        return (
          str.customer.name.toLowerCase().includes(search.toLowerCase()) ||
          (str.customer.cod !== null &&
            str.customer.cod.toLowerCase().includes(search.toLowerCase()))
        );
      })
    : [];

  let filteredByStatus = (status) =>
    filtered.filter((str) => {
      if (str.status === status) {
        return true;
      }

      return false;
    });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  filtered = filtered.map((item) => {
    const customer = customersById[item.customer_id] || item.customer || {};
    const latest_honorary =
      customer.honorary_history && customer.honorary_history.length > 0
        ? customer.honorary_history[customer.honorary_history.length - 1]
        : null;
    const latest_honorary_at = latest_honorary
      ? moment(latest_honorary.start_at)
      : null;
    const months_since_last_honorary = latest_honorary_at
      ? moment().diff(latest_honorary_at, "months")
      : null;
    return {
      ...item,
      customer,
      latest_honorary,
      latest_honorary_at,
      months_since_last_honorary,
    };
  });

  filtered = filtered.filter((item) => {
    if (onlyNotReajusted) {
      return item.months_since_last_honorary >= 12;
    }

    return true;
  });

  const needReajust = filtered.filter(
    (str) => str.months_since_last_honorary >= 12
  ).length;

  return (
    <div>
      {drawerPay && (
        <DrawerMonthlyPayment
          data={drawerPay}
          setData={setDrawerPay}
          changeAmount={changeAmount}
          changePaid={changePaid}
          checkIfStepIsActive={checkIfStepIsActive}
          finishCurrentOnboardingStep={finishCurrentOnboardingStepAction}
        />
      )}

      <DrawerFilter
        initialValues={filter}
        visible={showFilterDrawer}
        setVisible={setShowFilterDrawer}
      />

      <ModuleHeaderWrapper>
        <ModuleHeader
          breadcrumbs={["Financeiro", "Mensalidades"]}
          title={"Mensalidades"}
          // search={
          //   <Search
          //     placeholder="Pesquisar por cód ou cliente"
          //     onChange={(e) => setSearch(e.target.value)}
          //     style={{ maxWidth: 300 }}
          //   />
          // }
          actions={
            <>
              <Button
                type="default"
                icon="filter"
                onClick={() => setShowFilterDrawer(true)}
              >
                {moment(filter.from).format("DD/MM/YYYY")} -{" "}
                {moment(filter.to).format("DD/MM/YYYY")}
              </Button>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      key="1"
                      onClick={() => handleGenerate(needReajust)}
                    >
                      Gerar Mensalidades
                    </Menu.Item>
                    <Menu.Item key="2" onClick={handleExportToExcel}>
                      Exportar para arquivo CSV (excel)
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  type="primary"
                  loading={isGenerateLoading}
                  disabled={isGenerateLoading}
                  icon="menu"
                >
                  Ações
                </Button>
              </Dropdown>
              <DownloadBatch
                url="/monthly/payments/print/batch"
                filename="mensalidade.zip"
                ids={selectedRowKeys}
                fileWillBeDownloaded={selectedRowKeys.length <= 10}
              >
                <Button type="primary" icon="printer">
                  Imprimir
                </Button>
              </DownloadBatch>
            </>
          }
        />
      </ModuleHeaderWrapper>

      <Row gutter={16} style={{ paddingTop: 10 }}>
        <Col span={4}>
          <Card
            style={{ marginBottom: `10px` }}
            bodyStyle={{ padding: "15px" }}
          >
            <Statistic
              title="Total"
              value={
                sum(filtered, "amount") +
                sum(filtered, "additionals_amount") +
                sum(filtered, "amount13")
              }
              precision={2}
              formatter={(v) => toBRL(v)}
            />
            {show13() && (
              <Statistic
                title={<small>Mensalidades</small>}
                value={sum(filtered, "amount")}
                precision={2}
                formatter={(v) => toBRL(v)}
                valueRender={(val) => <small>{val}</small>}
              />
            )}
            {show13() && (
              <Statistic
                title={<small>Adicionais</small>}
                value={sum(filtered, "additionals_amount")}
                precision={2}
                formatter={(v) => toBRL(v)}
                valueRender={(val) => <small>{val}</small>}
              />
            )}
            {show13() && (
              <Statistic
                title={<small>13º Salário</small>}
                value={sum(filtered, "amount13")}
                precision={2}
                formatter={(v) => toBRL(v)}
                valueRender={(val) => <small>{val}</small>}
              />
            )}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            style={{ marginBottom: `10px` }}
            bodyStyle={{ padding: "15px" }}
          >
            <Statistic
              title="Pago"
              value={sum(filtered, "paid") + sum(filtered, "paid13")}
              precision={2}
              valueStyle={{ color: "#52c41a" }}
              formatter={(v) => toBRL(v)}
            />
            {show13() && (
              <Statistic
                title={<small>Mensalidades & adicionais</small>}
                value={sum(filtered, "paid")}
                precision={2}
                formatter={(v) => toBRL(v)}
                valueRender={(val) => <small>{val}</small>}
              />
            )}
            {show13() && (
              <Statistic
                title={<small>13º Salário</small>}
                value={sum(filtered, "paid13")}
                precision={2}
                formatter={(v) => toBRL(v)}
                valueRender={(val) => <small>{val}</small>}
              />
            )}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            style={{ marginBottom: `10px` }}
            bodyStyle={{ padding: "15px" }}
          >
            <Statistic
              title="Saldo"
              value={
                sum(filtered, "amount") +
                sum(filtered, "additionals_amount") -
                sum(filtered, "paid") +
                (sum(filtered, "amount13") - sum(filtered, "paid13"))
              }
              precision={2}
              valueStyle={{ color: "#f5222d" }}
              formatter={(v) => toBRL(v)}
            />
            {show13() && (
              <Statistic
                title={<small>Mensalidade & adicionais</small>}
                value={
                  sum(filtered, "amount") +
                  sum(filtered, "additionals_amount") -
                  sum(filtered, "paid")
                }
                precision={2}
                formatter={(v) => toBRL(v)}
                valueRender={(val) => <small>{val}</small>}
              />
            )}
            {show13() && (
              <Statistic
                title={<small>13º Salário</small>}
                value={sum(filtered, "amount13") - sum(filtered, "paid13")}
                precision={2}
                formatter={(v) => toBRL(v)}
                valueRender={(val) => <small>{val}</small>}
              />
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{ marginBottom: `10px` }}
            bodyStyle={{ padding: "15px" }}
          >
            <Row>
              <Col span={12}>
                <Statistic
                  title={
                    <span>
                      <Badge status="warning" /> Inadimplentes
                    </span>
                  }
                  value={
                    sum(filteredByStatus("Inadimplente"), "amount") -
                    sum(filteredByStatus("Inadimplente"), "paid")
                  }
                  precision={2}
                  formatter={(v) => toBRL(v)}
                  valueRender={(val) => <small>{val}</small>}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={
                    <span>
                      <Badge status="default" /> Ajuizados
                    </span>
                  }
                  value={
                    sum(filteredByStatus("Ajuizado"), "amount") -
                    sum(filteredByStatus("Ajuizado"), "paid")
                  }
                  precision={2}
                  formatter={(v) => toBRL(v)}
                  valueRender={(val) => <small>{val}</small>}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={4}>
          <Card
            onClick={() => setOnlyNotReajusted(!onlyNotReajusted)}
            style={{ marginBottom: `10px` }}
            bodyStyle={{
              padding: "15px",
              cursor: "pointer",
              backgroundColor: onlyNotReajusted ? "#f5f5f5" : "white",
            }}
          >
            <Tooltip title="Clientes que não tiveram a mensalidade reajustada a mais de 12 meses.">
              <Statistic
                title={
                  <>
                    <PulseBadge status="red" /> Reajuste
                  </>
                }
                value={needReajust}
                valueStyle={{ color: "#f5222d" }}
              />
            </Tooltip>
          </Card>
        </Col>
      </Row>

      <TableList
        filteredData={filtered}
        data={data}
        isLoading={isLoading}
        rowSelection={rowSelection}
        selectRow={selectRow}
        onAction={onAction}
        user={user}
      />
    </div>
  );
};

export default MonthlyPaymentList;

export const CACHE_ACTIONS = {
  dashboard: 'LOAD_DASHBOARD_FROM_CACHE',
  customers: 'LOAD_CUSTOMERS_FROM_CACHE',
  customersById: 'LOAD_CUSTOMERS_BY_ID_FROM_CACHE',
  users: 'LOAD_USERS_FROM_CACHE',
  usersById: 'LOAD_USERS_BY_ID_FROM_CACHE',
  registerTasks: 'LOAD_REGISTER_TASKS_FROM_CACHE',
  checklists: 'LOAD_CHECKLISTS_FROM_CACHE',
  conventions: 'LOAD_CONVENTIONS_FROM_CACHE',
  irpfs: 'LOAD_IRPFS_FROM_CACHE',
  monthlyPayments: 'LOAD_MONTHLY_PAYMENTS_FROM_CACHE',
  customerExtras: 'LOAD_CUSTOMER_EXTRAS_FROM_CACHE',
  refunds: 'LOAD_REFUNDS_FROM_CACHE',
  dashTasks: 'LOAD_DASHTASKS_FROM_CACHE',
  certificates: 'LOAD_CERTIFICATES_FROM_CACHE',
  procurations: 'LOAD_PROCURATIONS_FROM_CACHE',
  malhaFina: 'LOAD_MALHA_FINA_FROM_CACHE',
  documents: 'LOAD_DOCUMENTS_FROM_CACHE',
  leads: 'LOAD_LEADS_FROM_CACHE',
  leadsTable: 'LOAD_LEADS_TABLE_FROM_CACHE',
  leadsRT: 'LOAD_LEADS_RT_FROM_CACHE',
  links: 'LOAD_LINKS_FROM_CACHE',
  documentsDeadlines: 'LOAD_DOCUMENTS_DEADLINES_FROM_CACHE',
  honoraries: 'LOAD_HONORARIES_FROM_CACHE',
  emailsLogs: 'LOAD_EMAILS_LOGS_FROM_CACHE',
  feedbacks: 'LOAD_FEEDBACKS_FROM_CACHE',
  knowledges: 'LOAD_KNOWLEDGES_FROM_CACHE',
  settings: 'LOAD_SETTINGS_FROM_CACHE',
  boards: 'LOAD_BOARDS_FROM_CACHE',
  referrallProgramCampaigns: 'LOAD_REFERRAL_PROGRAM_CAMPAIGNS_FROM_CACHE',
  pluginsForms: 'LOAD_PLUGINS_FORMS_FROM_CACHE',
  reports: 'LOAD_REPORTS_FROM_CACHE',
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Drawer, Tabs } from 'antd';

import ListLinks from './components/ListLinks';
// import Reports from './components/Reports';
import ReportsByGroup from './components/ReportsByGroup';

export const ClicksCountModal = ({ isVisible, onClose }) => {
  return (
    <Drawer
      title="Contador de cliques"
      visible={isVisible}
      onClose={onClose}
      width={'80%'}
      placement="right"
      maskClosable={false}
      destroyOnClose={true}
      style={{
          height: '100%',
          overflow: 'auto',
          paddingBottom: 53,
      }}
    >
      <Tabs type="card">
          <Tabs.TabPane tab="Links" key="1">
            <ListLinks 
              isVisible={isVisible}
            />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Relatório" key="2">
            <Reports />
          </Tabs.TabPane> */}
        <Tabs.TabPane tab="Relatório" key="3">
            <ReportsByGroup />
          </Tabs.TabPane>
        </Tabs>
    </Drawer>
  );
};

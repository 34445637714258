import update from 'immutability-helper';

import moment from "moment";

import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

import {
    LOAD_MONTHLY_PAYMENTS,
    DELETE_HONORARY,
    MARK_AS_PROCESSED,
    MARK_AS_DEFAULTING,
} from './actions';

import * as drawerMonthlyPaymentActions from '~/containers/DrawerMonthlyPayment/actions';

const DEFAULT_CACHE_KEY = CACHE_ACTIONS.monthlyPayments;
export const defaultState = {
    data: [],
    filter: {
        from: moment().format("YYYY-MM-01"),
        to: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        status:  "all",
        filter_by: 'due_at',
        customer_id: 'all'
    },
    isLoading: false,
};

const ACTION_HANDLERS = {
	[DEFAULT_CACHE_KEY]: (state, action) => {
        return action.payload;
    },

    [LOAD_MONTHLY_PAYMENTS.INIT]: (state, action) => {
        return update(state, {
            isLoading: { $set: true },
        });
    },

    [LOAD_MONTHLY_PAYMENTS.SUCCESS]: (state, action) => {
        return update(state, {
            isLoading: { $set: false },
            data: { $set: action.payload.data.data },
            filter: { $set: action.meta.previousAction.payload.filter }
        });
    },

    [drawerMonthlyPaymentActions.SAVE.SUCCESS]: (state, action) => {
        return update(state, {
            data: { $set: state.data.map(item => item.id !== action.meta.previousAction.id? item : action.payload.data.data) }
        });
    },

    [DELETE_HONORARY.SUCCESS]: (state, action) => {
        return update(state, {
            data: { $set: state.data.filter(item => item.id !== action.meta.previousAction.id) }
        });
    },

    [MARK_AS_PROCESSED.SUCCESS]: (state, action) => {
        return update(state, {
            data: { $set: state.data.map(item => item.id !== action.meta.previousAction.id? item : action.payload.data.data) }
        });
    },

    [MARK_AS_DEFAULTING.SUCCESS]: (state, action) => {
        return update(state, {
            data: { $set: state.data.map(item => item.id !== action.meta.previousAction.id? item : action.payload.data.data) }
        });
    }
};

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);
  
    const updateCache = [
        LOAD_MONTHLY_PAYMENTS.SUCCESS,
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: DEFAULT_CACHE_KEY,
            data: newState
        });
    }

    return newState;
};

export default reducer;
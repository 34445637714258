import React from "react";
import "moment/locale/pt-br";

import {
  ButtonsWrapper,
  Container,
  Content,
  RegistrationButton,
} from "./styles";
import { ModuleHeader } from '~/components/ModuleHeader';

export const Header = ({
  showDrawer,
  isSeventhOnboardingStepActive,
}) => {
  return (
    <Container>
      <ModuleHeader 
        breadcrumbs={['Recuperação Tributária']} 
        actions={
          <RegistrationButton
            type="primary"
            onClick={() => showDrawer()}
            style={{ zIndex: isSeventhOnboardingStepActive ? 999 : "auto" }}
          >
            Cadastro
          </RegistrationButton>
        }
      />
    </Container>
  );
};

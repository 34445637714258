import React from "react";
import { useSelector } from "react-redux";

import Layout from "../../../components/UI/Layout/Layout";
import MonthlyPaymentList from "./MonthlyPaymentList";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";

export const ScreensMonthlyPayment = () => {
  const { isLoading } = useSelector((state) => state.monthlyPayment);

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <MonthlyPaymentList />
      </Layout>
    </div>
  );
};

import React from 'react';
import { Badge } from 'antd';
import styled from 'styled-components';

const PulseBadgeWrapper = styled.div`
    display: inline-block;

    .ant-badge-status-dot {
        animation: pulse 2s infinite;
        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.8);
            }
            100% {
                transform: scale(1);
            }
        }
    }
`;

const PulseBadge = (props) => {
    return (
        <PulseBadgeWrapper>
            <Badge 
                {...props}
            >
                {props.children}
            </Badge>
        </PulseBadgeWrapper>
    );
};

export default PulseBadge;
import React, { Component } from "react";
import FileDownload from "js-file-download";
import { request } from "../Instance";
import PropTypes from "prop-types";
import { message } from "antd";

class DownloadBatch extends Component {
  state = {};

  static propTypes = {
    url: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  };

  print = () => {
    const { fileWillBeDownloaded = true, ids } = this.props;
    request("post", this.props.url, { ids }, true, {
      responseType: fileWillBeDownloaded ? "blob" : "json",
    }).then((response) => {
      if (fileWillBeDownloaded) {
        FileDownload(response.data, this.props.filename);
      } else {
        message.success(response.data.message);
      }
    });
  };

  render() {
    let props = this.props;

    return React.cloneElement(props.children, {
      onClick: this.print,
      disabled: props.ids.length == 0,
    });
  }
}

export default DownloadBatch;

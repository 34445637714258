import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Row, Col, Select } from 'antd';
import moment from 'moment';

import * as pluginsActions from "~/containers/Plugins/actions";

const { Column } = Table;
const { Option } = Select;

const TableReports = ({settingsGroup, settingsAcquisition, source, loading}) => {
    return (
        <div>
            <Table
                rowKey="id"
                dataSource={source}
                bordered
                pagination={false}
                className="mb-20"
                scroll={{ x: 1800 }}
                loading={loading}
            >
                <Column
                    title="Grupo" 
                    dataIndex="group_id" 
                    key="group_id" 
                    ellipsis={true}
                    width={300} 
                    fixed="left"
                    filterMultiple={true}
                    filters={settingsGroup.map((item) => ({ text: item.value, value: item.id }))}
                    onFilter={(value, record) => record.acquisition_id === value}
                    render={(group_id) => {
                        const group = settingsGroup.find((item) => parseInt(item.id) === parseInt(group_id));
                        return group ? group.value : '';
                    }}
                />
                <Column
                    title="Origem" 
                    dataIndex="acquisition_id" 
                    key="acquisition_id" 
                    ellipsis={true}
                    width={300} 
                    fixed="left"
                    filterMultiple={true}
                    filters={settingsAcquisition.map((item) => ({ text: item.value, value: item.id }))}
                    onFilter={(value, record) => record.acquisition_id === value}
                    render={(acquisition_id) => {
                        const acquisition = settingsAcquisition.find((item) => parseInt(item.id) === parseInt(acquisition_id));
                        return acquisition ? acquisition.value : '';
                    }}
                />
                <Column
                    title="Janeiro"
                    dataIndex="reports.january"
                    key="reports.january"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Fevereiro"
                    dataIndex="reports.february"
                    key="reports.february"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Março"
                    dataIndex="reports.march"
                    key="reports.march"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Abril"
                    dataIndex="reports.april"
                    key="reports.april"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Maio"
                    dataIndex="reports.may"
                    key="reports.may"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Junho"
                    dataIndex="reports.june"
                    key="reports.june"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Julho"
                    dataIndex="reports.july"
                    key="reports.july"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Agosto"
                    dataIndex="reports.august"
                    key="reports.august"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Setembro"
                    dataIndex="reports.september"
                    key="reports.september"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Outubro"
                    dataIndex="reports.october"
                    key="reports.october"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Novembro"
                    dataIndex="reports.november"
                    key="reports.november"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Dezembro"
                    dataIndex="reports.december"
                    key="reports.december"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Total"
                    dataIndex="reports.total"
                    key="reports.total"
                    align="center"
                    width={100}
                    ellipsis={true}
                    fixed="right"
                />
            </Table>
        </div>
    );
}

const ReportsByGroup = () => {
    const dispatch = useDispatch();
    const settingsAcquisition = useSelector((state) => state.settings["leads.acquisition"]);
    const settingsGroup = useSelector((state) => state.settings["links.group_id"]);
    const [source, setSource] = useState([]);
    const [sourceGroup, setSourceGroup] = useState([]);
    const [year, setYear] = useState(moment().year());
    const [group, setGroup] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let data = [];
                const result = await dispatch(pluginsActions.loadReportsFromCountClicks(year));

                const groups = result.payload.data.reduce((acc, item) => {
                    if (!acc.includes(item.group_id)) {
                        acc.push(item.group_id);
                    }
                    return acc;
                }, []);

                data = groups.map((group_id) => {
                    var children = result.payload.data.filter((item) => item.group_id === group_id);
                    return({
                        acquisition_id: null,
                        group_id: group_id,
                        id: group_id,
                        reports: {
                            january: children.reduce((acc, item) => acc + item.reports.january, 0),
                            february: children.reduce((acc, item) => acc + item.reports.february, 0),
                            march: children.reduce((acc, item) => acc + item.reports.march, 0),
                            april: children.reduce((acc, item) => acc + item.reports.april, 0),
                            may: children.reduce((acc, item) => acc + item.reports.may, 0),
                            june: children.reduce((acc, item) => acc + item.reports.june, 0),
                            july: children.reduce((acc, item) => acc + item.reports.july, 0),
                            august: children.reduce((acc, item) => acc + item.reports.august, 0),
                            september: children.reduce((acc, item) => acc + item.reports.september, 0),
                            october: children.reduce((acc, item) => acc + item.reports.october, 0),
                            november: children.reduce((acc, item) => acc + item.reports.november, 0),
                            december: children.reduce((acc, item) => acc + item.reports.december, 0),
                            total: children.reduce((acc, item) => acc + item.reports.total, 0)
                        },
                        children
                    });
                });

                setSourceGroup(data);
                setSource(result.payload.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [year]);

    return (
        <div>
            <Row gutter={16} type="flex" align="middle" justify="end">
                <Col span={4} style={{ textAlign: 'right' }} className="mb-20">
                    <Select 
                        defaultValue={true} 
                        onChange={(value) => setGroup(value)}
                    >
                        <Option value={true}>Exibir agrupado</Option>
                        <Option value={false}>Exibir individual</Option>
                    </Select>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }} className="mb-20">
                    <Select 
                        defaultValue={year} 
                        onChange={(value) => setYear(value)}
                    >
                        {[...Array(5).keys()].map((item) => {
                            return (
                                <Option key={moment().year() - item} value={moment().year() - item}>
                                    {moment().year() - item}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>
            <TableReports 
                settingsAcquisition={settingsAcquisition}
                settingsGroup={settingsGroup}
                source={group ? sourceGroup : source}
                loading={loading}
            />
        </div>
    );
}

export default ReportsByGroup;
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

import * as customerActions from '~/containers/Customer/actions';

const defaultState = {
    list: [],
    isLoading: true
}

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.customers]: (state, action) => {
        return action.payload;
    },

    [CACHE_ACTIONS.procurations]: (state, action) => {
        return {
            ...state,
            isLoading: false,
            list: action.payload.customers
        }
    },
    
    'GENERATE_ACCESS_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map((item) => {
                if (item.id !== action.payload.data.data.id) {
                    return item;
                }

                return {
                    ...item,
                    ...action.payload.data.data
                }
            }),
        };
    },

    'RESEND_ACCESS_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map((item) => {
                if (item.id !== action.payload.data.data.id) {
                    return item;
                }

                return {
                    ...item,
                    ...action.payload.data.data
                }
            }),
        };
    },

    'LOAD_CUSTOMERS_SUCCESS': (state, action) => {
        return {
            ...state,
            list: action.payload.data,
            isLoading: false
        };
    },

    [customerActions.UPDATE_PARTNER.SUCCESS]: (state, action) => {
        return {
            ...state,
            list: state.list.map((item) => {
                if (item.id !== action.payload.data.data.customer_id) {
                    return item;
                }

                return {
                    ...item,
                    partners: action.payload.data.data.partners
                }
            })
        };
    },

    'REGISTER_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            list: [
                ...state.list,
                action.payload.data.data
            ]
        };
    },

    'DELETE_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.filter(item => item.id !== action.meta.previousAction.id)
        };
    },

    'SAVE_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : {
                ...item,
                ...action.payload.data.data
            })
        };
    },

    'ADD_MONTHLY_HONORARY_SUCECESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
        };
    },

    'INACTIVATE_CUSTOMER_SUCCESS': (state, action) => {
        if(action.payload.data.status==='success'){
            return {
                ...state,
                list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
            }
        }else{
            return state;
        }
    },

    'ACTIVATE_CUSTOMER_SUCCESS': (state, action) => {
        if(action.payload.data.status==='success'){
            return {
                ...state,
                list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
            }
        }else{
            return state;
        }
    },

    'FORCE_ACTIVATE_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
        };
    },

    'INACTIVATE_CUSTOMER_REMOVE_TASKS_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
        };
    },

    /*
    ** Dashtask
    */
    'REGISTER_DASHTASK_ITEM_SUCCESS': (state, action) => {
        if(parseInt(action.payload.data.data.category) === 1){
            return {
                ...state,
                list: [
                    ...state.list,
                    ...action.payload.data.data.custom_fields
                ]
            };
        }else{
            return state;
        }
    },

    'UPDATE_DASHTASK_ITEM_SUCCESS': (state, action) => {
        if(parseInt(action.payload.data.data.category) === 1){
            return {
                ...state,
                list: state.list.map(item => item.id !== action.payload.data.data.customer_id? item : action.payload.data.data.custom_fields)
            }
        }else{
            return state
        }
    },

    'ADD_MONTHLY_HONORARY_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
        };
    },

    'REMOVE_MONTHLY_HONORARY_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.meta.previousAction.customer_id? item : {
                ...item,
                honorary_history: [...item.honorary_history.filter(h => h.id !== action.meta.previousAction.id)]
            })
        };
    },

    'UPDATE_APP_USER_SUCCESS': (state, action) => {
        const { customerId, appUserId } = action.meta.previousAction.payload.request;
        const { app_user: updatedAppUser } = action.payload.data;

        return {
            ...state,
            list: state.list.map((customer) => {
                if (customer.id !== customerId) {
                    return customer;
                }

                const updatedAppUsers = customer.app_users.map((appUser) => {
                    if (appUser.id !== appUserId) {
                        return appUser;
                    }

                    return {
                        id: updatedAppUser.id,
                        name: updatedAppUser.name,
                        email: updatedAppUser.email,
                        last_access_at: updatedAppUser.last_access_at,
                        pivot: {
                            app_user_id: updatedAppUser.id,
                            customer_id: customerId
                        }
                    }
                });

                return {
                    ...customer,
                    app_users: updatedAppUsers
                }
            })
        }
    },

    'DELETE_APP_USER_SUCCESS': (state, action) => {
        const { customerId, appUserId } = action.meta.previousAction.payload;

        return {
            ...state,
            list: state.list.map((customer) => {
                if (customer.id !== customerId) {
                    return customer;
                }

                return {
                    ...customer,
                    app_users: customer.app_users.filter((appUser) => appUser.id !== appUserId)
                }
            })
        };
    },

    'INVITE_APP_USER_SUCCESS': (state, action) => {
        const { invited_user } = action.payload.data;
        const { customerId } = action.meta.previousAction.payload;

        return {
            ...state,
            list: state.list.map((customer) => {
                if (customer.id !== customerId) {
                    return customer;
                }

                return {
                    ...customer,
                    app_users: [
                        ...customer.app_users,
                        invited_user
                    ]
                }
            })
        }
    }
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        'LOAD_CUSTOMERS_SUCCESS',
        'REGISTER_CUSTOMER_SUCCESS',
        'DELETE_CUSTOMER_SUCCESS',
        'SAVE_CUSTOMER_SUCCESS',
        'ADD_MONTHLY_HONORARY_SUCECESS',
        'INACTIVATE_CUSTOMER_SUCCESS',
        'ACTIVATE_CUSTOMER_SUCCESS',
        'FORCE_ACTIVATE_CUSTOMER_SUCCESS',
        'INACTIVATE_CUSTOMER_REMOVE_TASKS_SUCCESS',
        'REGISTER_DASHTASK_ITEM_SUCCESS',
        'UPDATE_DASHTASK_ITEM_SUCCESS',
        'ADD_MONTHLY_HONORARY_SUCCESS',
        'REMOVE_MONTHLY_HONORARY_SUCCESS',
        'UPDATE_APP_USER_SUCCESS',
        'DELETE_APP_USER_SUCCESS',
        'INVITE_APP_USER_SUCCESS',
        customerActions.UPDATE_PARTNER.SUCCESS,
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.customers,
            data: newState
        });
    }

    return newState;
}

export default reducer;
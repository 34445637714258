import { Button, Table, Tag, Badge } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Container, Header, Main, SearchInput } from "./styles";
import { getFormattedEvent } from "~/containers/Documents/helpers";
import { EmailsLogsModal } from "./components/EmailsLogsModal";
import { EmailBodyPreviewModal } from "./components/EmailBodyPreviewModal";
import { ModuleHeader } from '~/components/ModuleHeader';
import { STORAGE } from "~/constants/storage.constants";
import { Subtitle } from '~/components/ModuleHeader/styles';
import { normalizeToFilter } from '../KnowledgeBase/utils';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';

const { Column } = Table;

export const EmailsLogs = ({ refresh, setSelectedDocument }) => {
  const { pathname } = useLocation();
  const { emailsLogs, isLoading } = useSelector((state) => ({
    emailsLogs: state.emailsLogs.list,
    isLoading: state.emailsLogs.isLoading,
  }));
  const [filter, setFilter] = useState('');
  const [emailsLogsModal, setEmailsLogsModal] = useState({
    isOpen: false,
    logs: [],
  });
  const [emailBodyPreviewModal, setEmailBodyPreviewModal] = useState({
    isOpen: false,
    data: null,
    initialLogId: [],
  });
  const [isBtnErrorsSelected, setIsBtnErrorsSelected] = useState(pathname.includes('erros') ? true : false);

  const openEmailsLogsModal = (logs) => {
    setEmailsLogsModal({
      isOpen: true,
      logs,
    });
  };

  const closeEmailsLogsModal = () => {
    setEmailsLogsModal({
      isOpen: false,
      logs: [],
    });
  };

  const openEmailBodyPreviewModal = ({ initialLogId, data }) => {
    setEmailBodyPreviewModal({
      isOpen: true,
      data,
      initialLogId,
    });
  };

  const closeEmailBodyPreviewModal = () => {
    setEmailBodyPreviewModal({
      isOpen: false,
      data: null,
      initialLogId: null,
    });
  };

  let filteredEmailsLogs = emailsLogs.filter((log) => {
    const customerNameMatches = normalizeToFilter(log.customer_name).includes(
      filter
    );

    const emailMatches = normalizeToFilter(log.email).includes(filter);

    return customerNameMatches || emailMatches;
  });

  const failedEmails = filteredEmailsLogs.filter(
    (log) =>
      log.related_logs[0].event === "failed" ||
      log.related_logs[0].event === "complained" ||
      log.related_logs[0].event === "unsubscribed"
  );

  filteredEmailsLogs = isBtnErrorsSelected ? failedEmails : filteredEmailsLogs;

  const formattedEmailsLogs = filteredEmailsLogs.map((log) => ({
    ...log,
    formatted_sent_at: moment(log.sent_at).format("DD/MM/YYYY HH:mm:ss"),
    email_status: getFormattedEvent(log.related_logs[0].event),
  }));

  console.log('formattedEmailsLogs', formattedEmailsLogs);

  return (
    <Container>
      <Header>
        <ModuleHeader
          breadcrumbs={['Relatórios', 'E-mails enviados']}
          title={
            <span>
              E-mails enviados <Subtitle>| nos últimos 7 dias</Subtitle>
            </span>
          }
          search={
            <>
              <SearchInput
                placeholder="Pesquisar pelo nome do cliente ou e-mail"
                onChange={({ target }) => setFilter(normalizeToFilter(target.value))}
              />
              <Button
                icon={"sync"}
                onClick={refresh}
              />
            </>
          }
          actions={failedEmails.length > 0 && (
            <Badge count={failedEmails.length}>
              <Button 
                type={isBtnErrorsSelected ? 'danger' : 'default'}
                style={{ marginLeft: 10, color: isBtnErrorsSelected ? 'white' : 'red' }}
                onClick={() => setIsBtnErrorsSelected(!isBtnErrorsSelected)}>
                E-mails não enviados
              </Button>
            </Badge>
          )}
        />
      </Header>
      <Main>
        <Table
          loading={isLoading}
          rowKey="id"
          bordered
          dataSource={formattedEmailsLogs}
          scroll={{ y: "calc(100vh - 300px)" }}
          pagination={getDefaultPaginationConfig({
            storageKey: STORAGE.defaultPageSize.emailsLogs
          })}
        >
          <Column title="Título" key="title" dataIndex="title" />
          <Column title="Cliente" key="customer" dataIndex="customer_name" />
          <Column title="E-mail" key="email" dataIndex="email" />
          <Column
            title="Data de envio"
            key="formatted_sent_at"
            defaultSortOrder="ascend"
            sorter={(a, b) => b.sent_at.localeCompare(a.sent_at)}
            width={170}
            dataIndex="formatted_sent_at"
          />
          <Column
            title="Status do e-mail"
            key="email_status"
            dataIndex="email_status"
            filters={[
              {
                text: 'Enviado',
                value: 'delivered',
              },
              {
                text: 'Aberto',
                value: 'opened',
              },
              {
                text: 'Clicado',
                value: 'clicked',
              },
              {
                text: 'Marcado como spam',
                value: 'complained',
              },
              {
                text: 'Falhou',
                value: 'failed',
              },
              {
                text: 'Inscrição cancelada',
                value: 'unsubscribed',
              },
              {
                text: 'Envio solicitado',
                value: 'pendent',
              },
            ]}
            onFilter={(value, { related_logs }) => related_logs[0].event === value}
            align="center"
            width={150}
            render={(status, { related_logs, customer_file_id }) => {
              if (!status) return null;
              return (
                <Tag
                  style={{ cursor: "pointer" }}
                  onClick={() => customer_file_id ? setSelectedDocument(customer_file_id) : openEmailsLogsModal(related_logs)}
                  color={status.color}
                >
                  {status.text}
                </Tag>
              );
            }}
          />
          <Column
            title="Prévia"
            key="preview"
            align="center"
            width={80}
            dataIndex="initial_log_id"
            render={(initialLogId, record) => {
              if (!initialLogId) return null;
              return (
                <Button
                  icon="eye"
                  onClick={() => openEmailBodyPreviewModal({ initialLogId, data: record })}
                />
              )
            }}
          />
        </Table>
      </Main>
      <EmailsLogsModal
        isOpen={emailsLogsModal.isOpen}
        onClose={closeEmailsLogsModal}
        logs={emailsLogsModal.logs}
      />
      <EmailBodyPreviewModal
        isOpen={emailBodyPreviewModal.isOpen}
        onClose={closeEmailBodyPreviewModal}
        initialLogId={emailBodyPreviewModal.initialLogId}
        data={emailBodyPreviewModal.data}
      />
    </Container>
  );
};

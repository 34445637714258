import { DatePicker, Empty, Pagination, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadDocumentsDeadlines } from "./actions";

import {
  Container,
  DeadlineItem,
  DeadLineName,
  Deadlines,
  DeadlineSkeletonItem,
  DeadlineSummary,
  DeadlineSummaryItem,
  Footer,
  Header,
  Main,
} from "./styles";

import "./custom-skeleton.css";
import { CustomersModal } from "./CustomersModal";
import { getSummaryData } from "./helpers";
import { ModuleHeader } from '~/components/ModuleHeader';

const { MonthPicker } = DatePicker;

export const DocumentsDeadlines = () => {
  const dispatch = useDispatch();
  const {
    documentsDeadlines: { list: documentsDeadlines, isLoading },
  } = useSelector((state) => state);

  const [customersModal, setCustomersModal] = useState({
    isVisible: false,
    data: null,
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [category] = useState("taxes");

  function showCustomersModal(data) {
    setCustomersModal({
      isVisible: true,
      data,
    });
  }

  function hideCustomersModal() {
    setCustomersModal({
      isVisible: false,
      data: null,
    });
  }

  const indexOfLastTask = pagination.currentPage * pagination.pageSize;
  const indexOfFirstTask = indexOfLastTask - pagination.pageSize;
  const paginatedTasks = documentsDeadlines.slice(
    indexOfFirstTask,
    indexOfLastTask
  );

  const filteredTasks = paginatedTasks.filter((item) => {
    return item.category === category;
  });

  return (
    <Container>
      <Header>
        <ModuleHeader 
          breadcrumbs={['Relatórios', 'Prazos']} 
          title="Prazos (impostos)" 
          actions={
            <MonthPicker
              allowClear={false}
              format="MM/YYYY"
              defaultValue={moment()}
              onChange={(date) => {
                dispatch(
                  loadDocumentsDeadlines({
                    date: date.endOf("month").format("YYYY-MM-DD"),
                  })
                );
              }}
            />
          }
        />
      </Header>
      <Main>
        {isLoading ? (
          <>
            {Array(6)
              .fill(1)
              .map((_, index) => (
                <DeadlineSkeletonItem key={index} />
              ))}
          </>
        ) : !isLoading && filteredTasks.length === 0 ? (
          <Empty
            description="Nenhuma tarefa encontrada."
            style={{ marginTop: 200 }}
          />
        ) : (
          <Deadlines>
            {filteredTasks.map(({ id, name, registers }) => {
              const { greenCount, orangeCount, redCount } = getSummaryData(registers);
              return (
                <DeadlineItem
                  key={id}
                  onClick={() => showCustomersModal(registers)}
                >
                  <DeadLineName>{name}</DeadLineName>
                  <DeadlineSummary>
                    <DeadlineSummaryItem color="#389e0d">
                      <Tooltip
                        title={`${greenCount} documento${greenCount > 1 ? 's foram entregues' : ' foi entregue'} antes de 3 dias do vencimento`}
                      >
                        {greenCount}
                      </Tooltip>
                    </DeadlineSummaryItem>
                    <DeadlineSummaryItem color="#d4b106">
                      <Tooltip
                        title={`${orangeCount} documento${orangeCount > 1 ? 's foram entregues' : ' foi entregue'} 3 dias antes do vencimento`}
                      >
                        {orangeCount}
                      </Tooltip>
                    </DeadlineSummaryItem>
                    <DeadlineSummaryItem color="#f5222d">
                      <Tooltip
                        placement="top"
                        title={`${redCount} documento${redCount > 1 ? 's foram entregues' : ' foi entregue'} com menos de 3 dias de antecedência ao vencimento`}
                      >
                        {redCount}
                      </Tooltip>
                    </DeadlineSummaryItem>
                    <DeadlineSummaryItem color="#000">
                      <Tooltip placement="top" title="Total">
                        {greenCount + orangeCount + redCount}
                      </Tooltip>
                    </DeadlineSummaryItem>
                  </DeadlineSummary>
                </DeadlineItem>
              );
            })}
          </Deadlines>
        )}
      </Main>
      <Footer>
        {!isLoading && filteredTasks.length > 0 && (
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.currentPage}
            total={documentsDeadlines.length}
            showSizeChanger
            onChange={(page) => {
              setPagination({ ...pagination, currentPage: page });
            }}
            onShowSizeChange={(page, size) => {
              setPagination({ currentPage: page, pageSize: size });
            }}
          />
        )}
      </Footer>
      <CustomersModal
        isVisible={customersModal.isVisible}
        data={customersModal.data}
        hideCustomersModal={hideCustomersModal}
      />
    </Container>
  );
};

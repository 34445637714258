import React, { useState } from 'react';
import moment from 'moment';
import { Empty, Icon } from 'antd';
import { connect } from 'react-redux';

import {
  Card,
  CardCategory,
  CardContent,
  CardDescription,
  Cards,
  CardTime,
  CardTitle,
  CategoriesList,
  CategoryCheckbox,
  CategoryItem,
  ClearFilters,
  Container,
  EditCategory,
  FavIcon,
  SideBar,
  FilterBy,
  SideBarHeader,
  FilterInput,
  Header,
  RegistrationButton,
  CategoryContainer,
  CategoryTitle,
  MainContainer,
  LoadingSkeleton,
} from './styles';
import * as linksActions from './actions';
import CategoriesModal from './Modals/CategoriesModal';
import { ModuleHeader } from '~/components/ModuleHeader';

const Links = ({
  openDrawer,
  links,
  openCategoriesModal,
  linkCategories,
  isLoading,
}) => {
  const [filter, setFilter] = useState({
    title: '',
    categories: [],
  });

  const filteredLinks = links.filter((link) => {
    const titleMatches = link.title
      .toLocaleLowerCase()
      .includes(filter.title.toLocaleLowerCase());

    const hasFilterCategories = filter.categories.length > 0;

    if (titleMatches) {
      if (hasFilterCategories) {
        const filterCategoriesMatches = filter.categories.includes(
          link.link_category_id
        );
        if (filterCategoriesMatches) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  });

  return (
    <Container>
      <Header>
        <ModuleHeader
          breadcrumbs={['Links de atalho']}
          title={
            <span>
              Links de atalho{' '}
              <span style={{ opacity: 0.6 }}>({links.length})</span>
            </span>
          }
          actions={
            <RegistrationButton type="primary" onClick={() => openDrawer()}>
              Cadastro <Icon type="plus" />
            </RegistrationButton>
          }
        />
      </Header>
      <SideBar>
        <SideBarHeader>
          <FilterBy>Filtrar por:</FilterBy>
          <ClearFilters
            type="link"
            onClick={() => setFilter({ title: '', categories: [] })}
          >
            Limpar filtros
          </ClearFilters>
        </SideBarHeader>
        <FilterInput
          placeholder="Título do link"
          value={filter.title}
          onChange={(event) =>
            setFilter({
              ...filter,
              title: event.target.value,
            })
          }
        />
        <CategoryContainer>
          <CategoryTitle>Categorias</CategoryTitle>
          <EditCategory type="link" onClick={openCategoriesModal}>
            Editar categoria
          </EditCategory>
        </CategoryContainer>
        <CategoriesList>
          {linkCategories.map((category) => (
            <CategoryItem key={category.id}>
              <CategoryCheckbox
                checked={filter.categories.includes(category.id)}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    setFilter({
                      ...filter,
                      categories: [...filter.categories, category.id],
                    });
                  } else {
                    setFilter({
                      ...filter,
                      categories: filter.categories.filter(
                        (categoryId) => categoryId !== category.id
                      ),
                    });
                  }
                }}
              >
                {category.name}
              </CategoryCheckbox>
            </CategoryItem>
          ))}
        </CategoriesList>
      </SideBar>
      <MainContainer>
        <Cards hasNotLinks={filteredLinks.length === 0}>
          {isLoading ? (
            Array(10)
              .fill(1)
              .map((_, index) => <LoadingSkeleton key={index} />)
          ) : filteredLinks.length === 0 ? (
            <Empty
              description="Nenhum link encontrado."
              style={{ marginTop: 300 }}
            />
          ) : (
            filteredLinks.map((link) => (
              <Card key={link.id} onClick={() => openDrawer(link)}>
                <FavIcon
                  src={`http://www.google.com/s2/favicons?domain=${link.address}`}
                />
                <CardContent>
                  <CardTitle
                    href={link.address}
                    target="_blank"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {link.title}
                  </CardTitle>
                  <CardDescription>{link.description}</CardDescription>
                  <CardCategory>{link.category.name}</CardCategory>
                </CardContent>
                <CardTime>
                  cadastrado {moment(link.created_at).fromNow()}
                </CardTime>
              </Card>
            ))
          )}
        </Cards>
      </MainContainer>
      <CategoriesModal />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  links: state.links.list,
  isLoading: state.links.isLoading,
  linkCategories: state.links.categories,
});

const mapDispatchProps = (dispatch) => ({
  openDrawer: (data) => dispatch(linksActions.openDrawer(data)),
  openCategoriesModal: () => dispatch(linksActions.openCategoriesModal()),
});

export default connect(mapStateToProps, mapDispatchProps)(Links);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Dropdown, Menu, Icon, Modal, Badge, Tooltip } from 'antd';

import { copyToClipBoard } from '~/utils/copy-to-clipboard';
import { getSubdomain } from "~/helpers/getSubdomain";

const frontBaseUrl = `http://${getSubdomain()}.${process.env.REACT_APP_DOMAIN}`;

const areYouSure = (id, handleDelete) => {
    return (
        Modal.confirm({
            title: 'Você tem certeza que deseja excluir este item?',
            content: 'Esta ação não poderá ser desfeita.',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                handleDelete(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    );
};


export const tableColumns = (settings, handleDelete, handleEdit) => [
    {
        title: 'Nome do Formulário',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 200,
    },
    {
        title: 'Modelo',
        key: 'modelo',
        dataIndex: 'type',
        width: 200,
        ellipsis: true,
        render: (type, record, index) => {
            if (type === 'form1') {
                return 'Básico';
            } else if (type === 'form2') {
                return 'Reunião';
            } else if (type === 'chatbot') {
                return 'Chatbot';
            } else {
                return 'Customizado';
            }
        }
    },
    {
        title: 'Link',
        key: 'token',
        dataIndex: 'token',
        width: 200,
        ellipsis: true,
        render: (token, record, index) => {
            if(record.type !== 'chatbot') {
                return (
                    <a href={`${frontBaseUrl}/leads/forms/${record.id}`} target="_blank" rel="noopener noreferrer">
                        {`${frontBaseUrl}/leads/forms/${record.id}`}
                    </a>
                );
            }

            return (
                <a href={`${frontBaseUrl}/f/${token}`} target="_blank" rel="noopener noreferrer">
                    {`${frontBaseUrl}/f/${token}`}
                </a>
            );
        }
    },
    {
        title: 'Logs',
        key: 'logs',
        dataIndex: 'logs',
        width: 100,
        align: 'center',
        ellipsis: true,
        render: (logs, record, index) => {
            if(record.type !== 'chatbot') return '-';
            return logs ? logs.length : 0;
        }
    },
    {
        title: '',
        key: 'actions',
        dataIndex: 'id',
        width: 80,
        render: (id, record, index) => (
        <center key={index}>
            <Dropdown overlay={
            <Menu>
                <Menu.Item key="edit" onClick={() => handleEdit(record)}>
                    <Icon type="edit"></Icon> Editar
                </Menu.Item>
                <Menu.Item key="copy" onClick={() => copyToClipBoard(record.type !== 'chatbot' ? `${frontBaseUrl}/leads/forms/${record.id}` : `${frontBaseUrl}/f/${record.token}`)}>
                    <Icon type="copy"></Icon> Copiar Link
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="delete" onClick={() => areYouSure(id, handleDelete)}>
                    <Icon type="delete"></Icon> Excluir
                </Menu.Item>
                </Menu>
                } trigger={['click']}>
                    <a className="ant-dropdown-link" href="#">
                        Ações <Icon type="down" />
                    </a>
            </Dropdown>
        </center>
        )
    }
];
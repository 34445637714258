import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Dropdown, Menu, Modal, Button, Input, Radio, Tooltip, Upload, message, Tag, Popover } from 'antd';
import { compare } from 'natural-orderby';
import Moment from 'react-moment';
import FileDownload from 'js-file-download';

import * as customersActions from '~containers/Customer/actions';
import { 
  deliveryMethod 
} from '~containers/Customer/data';

import { toBRL } from '../UI/InputCurrency';
import { get } from 'lodash';
import moment from 'moment';
import { AppUserAccessModal } from './Modals/AppUserAccessModal';
import { getSubdomain } from '~/helpers/getSubdomain';
import { finishCurrentOnboardingStepAction } from '../Onboarding/helpers';
import { request } from '../Instance';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { ModuleHeader } from '../ModuleHeader';
import { HeaderContainer, HeaderLeftContent, HeaderRightContent } from './styles';
import { AccountStorage } from '~/storages/account';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import { displaySocCategory } from './helpers';
import Tip from '~/components/Tip';
import { RadioButtonWrapper } from '~/components/UI/RadioButtonWrapper';

const { Column } = Table;
const { Dragger } = Upload;

const Search = Input.Search;

class CustomersList extends Component {
  state = {
    search: '',
    actived: 1,
    importCSV: false,
    appUserAccessModal: {
      isVisible: false,
      customer: null
    }
  }

  onAction = (e, customer) => {
    switch(e.key){
      case 'details': 
      this.props.showDetails(customer);
      break;
      case 'inactivate': 
      this.props.inactivateCustomer(customer.id)
      break;
      case 'activate': 
      this.props.activateCustomer(customer.id)
      break;
      case 'force_activate':
        Modal.confirm({
          title: 'Forçar ativação',
          content: <div>Este cliente será <strong>automaticamente ativado após a conclusão do processo no Dep. Societário</strong>. Você tem certeza que deseja forçar a ativação? <br />*Esta ação não poderá ser desfeita. <br/>*O cliente ativo será listado em tarefas e no módulo financeiro.</div>,
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.forceActivateCustomer(customer.id)
        });
      break;
      case 'edit':
      this.props.editCustomer(customer);
      break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este cliente?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteCustomer(customer.id)
        });
      break;
      case 'generateAccess':
        this.setState({
          appUserAccessModal: {
            isVisible: true,
            customer
          }
        })
      break;
      case 'resendAccess':
        this.props.resendAccess({ customerId: customer.id });
      break;
      default:
        return;
    }
  }

  handleCancel = () => this.setState({importCSV: false});

  downloadModel = () => {
    const link = document.createElement('a');
    link.href = '/importacao_de_clientes.xlsx';
    link.setAttribute('download', `importaca_de_clientes_modelo.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  exportToExcel = async () => {
    try {
      const { actived } = this.state;

      const response = await request(
        'POST',
        '/customers/export',
        {
          status: actived ? 'actived' : 'inactived'
        },
        true,
        {
          responseType: 'blob'
        }
      )
  
      const now = moment().format('DD_MM_YYYY_HH-mm-ss');
      FileDownload(response.data, `Clientes_${actived ? 'Ativos' : 'Inativos'}_${now}.xlsx`);
    } catch (error) {
      message.error('Não foi possível exportar os clientes para Excel. Tente novamente mais tarde.')
    }
  };

  hasContract = (customer) => {
    return customer.contract_files.length > 0;
  };

  hasRescission = (customer) => customer.rescission_files.length > 0;

  styleCompanyName = (customer) => {
    if(this.hasRescission(customer)){
      return {textDecoration: 'line-through', color: 'red'}
    }
    if(this.hasContract(customer)){
      return {fontWeight: 'bold', color: '#52c41a'}
    }
    return {}
  }

  checkIfAppAccessIsDisabled = (customer, canEditCustomers) => {
    const generatedAppUser = customer.generated_app_user;

    const appUserHasAlreadyAccessed = !!generatedAppUser && !!generatedAppUser.last_access_at;
    return !canEditCustomers || appUserHasAlreadyAccessed;
  }

  getAppAccessTooltipTitle = (customer) => {
    const generatedAppUser = customer.generated_app_user;
    const appUserHasAlreadyAccessed = !!generatedAppUser && !!generatedAppUser.last_access_at;

    if (appUserHasAlreadyAccessed) {
      return 'Este cliente já acessou o painel.';
    }

    return '';
  }
  
  checkIfStepIsActive = (step) => {
    const { isOnboardingActive, onboardingStep, isVideoModalVisible } = this.props;
    return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
  }

  handleCopyToClipBoard = () => {
    const domain = process.env.REACT_APP_CUSTOMER_DOMAIN;
    const link = `http://${getSubdomain()}.${domain}/cliente`;
    navigator.clipboard.writeText(link);
    message.success("Link da área do cliente copiado para a área de transferência!");
  }

  render() {
    const {
      customers,
      user,
      isCustomerDrawerVisible,
      finishCurrentOnboardingStep,
      onboardingStep,
      isLoading,
      companyTypes
    } = this.props;

    const { search, actived } = this.state;
    const _this = this;

    const isSecondOnboardStepActive = this.checkIfStepIsActive(2);
    const isTenthOnboardStepActive = this.checkIfStepIsActive(10);

    let filterCompanyType = [];
    companyTypes.map(item => filterCompanyType.push({text: item.value, value: item.id}));

    const uploadProps = {
      name: 'file',
      multiple: false,
      action: `${process.env.REACT_APP_API}/auth/customers/import`,
      headers: {Authorization: `Bearer ${AccountStorage.get().token}`, 'X-Tenant': getSubdomain() },
      onChange(info) {
        const { status } = info.file;
        // console.log(info.file, info.fileList);
       
        if (status === 'done') {
          message.success(`${info.file.response.message}`);
          _this.setState({importCSV: false});
          _this.props.loadCustomers();
          finishCurrentOnboardingStep({
            isActive: isSecondOnboardStepActive,
            step: onboardingStep,
            user
          });
        } 
        // else if (status === 'error') {
        //   message.error(`${info.file.name} file upload failed.`);
        // }
      },
    };

    const formattedCustomers = customers
      ? customers.map((customer) => {
          const { doc_delivery_method, generated_app_user, app_users } = customer;

          if (
            doc_delivery_method !== 2 ||
            !generated_app_user ||
            app_users.length === 0
          ) {
            return {
              ...customer,
              most_recent_app_user: null,
            };
          }

          return {
            ...customer,
            most_recent_app_user: app_users[0]
          }
        })
      : [];

    let filteredCustomers = formattedCustomers.filter((str) => {

      if((actived === 1 || actived === 0) && !str.enabled){
        return false;
      }

      if(actived === 2 && str.enabled){
        return false
      }

      if((str.inactived_at === null && !actived) || (str.inactived_at !== null && actived)){
        return false;
      }

      if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.email !== null && str.email.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.identification_number !== null && str.identification_number.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.cnpj_formated !== null && str.cnpj_formated.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.cod !== null && str.cod.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      return false;
    });

    const canCreateCustomers = user.permissions.includes(PERMISSIONS.customers.create);
    const canEditCustomers = user.permissions.includes(PERMISSIONS.customers.edit);
    const canDeleteCustomers = user.permissions.includes(PERMISSIONS.customers.delete);

    const customerWithoutHonoraries = filteredCustomers.find(({ honorary_history }) => honorary_history.length === 0);

    return (  
      <div>
        <Modal
          title="Importar clientes"
          visible={this.state.importCSV}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancelar
            </Button>,
            <Button type="primary" key="download" icon="download" onClick={this.downloadModel}>
              ARQUIVO MODELO
            </Button>
          ]}
        >
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Clique ou arraste a lista em excel aqui para fazer o upload</p>
          </Dragger>
        </Modal>
        <div style={{ 
          margin: `0px -16px`, 
          padding: '15px', 
          background: '#fff', 
          marginBottom: `10px` }}>
            <ModuleHeader 
              breadcrumbs={['Clientes']} 
              search={
                <Search 
                  placeholder="Pesquisar clientes" 
                  onChange={e => this.setState({search: e.target.value})}
                />
              }
              center={
                <>
                  <Radio.Group 
                    value={actived}
                    style={{ display: 'flex' }}
                    onChange={(e) => this.setState({actived: e.target.value})}>
                    <Radio.Button value={1} style={actived === 1?{borderColor: `#a0d911`, color: `#a0d911`}:{}}>
                      <RadioButtonWrapper>
                        Ativos
                        <Tip>Clientes ativos.</Tip>
                      </RadioButtonWrapper>
                    </Radio.Button>
                    <Radio.Button value={2} style={actived === 2?{borderColor: `#722ed1`, color: `#722ed1`, boxShadow: `-1px 0 0 0 #722ed1`}: {}}>
                      <RadioButtonWrapper>
                          Pendentes
                          <Tip>Clientes em processo no Dep. Societário aparecem aqui. Após serem movidos para concluído serão automaticamente listados em "ativos".</Tip>
                      </RadioButtonWrapper>
                    </Radio.Button>
                    <Radio.Button value={0} style={actived === 0 ?{borderColor: `#f5222d`, color: `#f5222d`, boxShadow: `-1px 0 0 0 #f5222d`}:{}}>
                      <RadioButtonWrapper>
                        Inativos
                        <Tip>Clientes inativos, são aqueles que não são mais seus clientes, portanto não aparecem nas listagens de tarefas etc..</Tip>
                      </RadioButtonWrapper>
                      </Radio.Button>
                    </Radio.Group>
                </>
              }
              actions={
                <>
                  <Button
                    type="dashed"
                    icon="copy"
                    onClick={this.handleCopyToClipBoard}
                  >
                    Aŕea do cliente
                  </Button>
                  {canCreateCustomers && (
                    <Dropdown
                      trigger={['click']}
                      overlay={
                        <Menu>
                            <Menu.Item
                              key="1"
                              onClick={() => this.props.editCustomer({})}
                              disabled={isSecondOnboardStepActive}
                            >
                              Cadastro Manual
                            </Menu.Item>
                            <Menu.Item key="2" onClick={() => this.setState({importCSV: true})}>
                                Importar via Excel
                            </Menu.Item>
                            <Menu.Item
                              key="3"
                              onClick={this.exportToExcel}
                              disabled={isSecondOnboardStepActive}
                            >
                              Exportar para Excel
                            </Menu.Item>
                        </Menu>
                      }
                    >
                      <Popover
                        placement="left"
                        content="Clique aqui e importe clientes via Excel"
                        visible={isSecondOnboardStepActive && !this.state.importCSV}
                        overlayStyle={{ zIndex: 1051 }}
                      >
                        <Button type="primary" style={{float: `right`, zIndex: isSecondOnboardStepActive ? 999 : 'auto'}}>
                          Cadastrar Cliente <Icon type="down" />
                        </Button>
                    </Popover>
                  </Dropdown>
                  )}
                </>
              }
            />
        </div>
        <div className={`table-clabs${isTenthOnboardStepActive ? ' onboarding-active' : ''}`}>
          <Table 
            rowKey="id"
            dataSource={filteredCustomers}
            loading={isLoading} 
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.customers
            })}
            bordered={true}
            rowClassName="row-clabs"
            scroll={{ x: actived?6270:6470, y: `${window.innerHeight-175}px`}}
          >
              <Column
                  title="Cod"
                  dataIndex="cod"
                  key="cod"
                  fixed="left"
                  width={100}
                  sorter={(a, b) => compare()(a.cod, b.cod)}
                  sortDirections={['descend', 'ascend']}
                  defaultSortOrder={'ascend'}
              />
              <Column
                  title="Empresa"
                  dataIndex="name"
                  key="name"
                  fixed="left"
                  width={500}
                  sorter={(a, b) => a.name && a.name.localeCompare(b.name)}
                  render={(text, record) => <span style={this.styleCompanyName(record)}>{text}</span>}
              />
              <Column
                  width={200}
                  title="Email"
                  dataIndex="email"
                  key="email"
                  sorter={(a, b) => a.email && a.email.localeCompare(b.email)}
                  ellipsis
              />
              <Column
                  title="CNPJ"
                  dataIndex="cnpj_formated"
                  key="cnpj_formated"
                  width={200}
                  sorter={(a, b) => a.identification_number && (a.identification_number-b.identification_number)}
                  ellipsis
              />
              <Column
                  title="Telefone"
                  dataIndex="phone_formated"
                  key="phone_formated"
                  width={200}
                  sorter={(a, b) => a.phone && (a.phone-b.phone)}
                  ellipsis
              />
              <Column
                  title="Regime Tributário"
                  dataIndex="type_taxation"
                  key="type_taxation"
                  width={200}
                  sorter={(a, b) => a.type_taxation && a.type_taxation.localeCompare(b.type_taxation)}
                  filters={[{
                    text: 'Doméstica',
                    value: 'Doméstica',
                  }, {
                    text: 'Lucro Presumido',
                    value: 'Lucro Presumido',
                  },
                  {
                    text: 'Lucro Real',
                    value: 'Lucro Real',
                  },
                  {
                    text: 'MEI',
                    value: 'MEI',
                  },
                  {
                    text: 'Profissional Liberal',
                    value: 'Profissional Liberal',
                  },
                  {
                    text: 'Simples Nacional',
                    value: 'Simples Nacional',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.type_taxation && record.type_taxation.indexOf(value) === 0}
              />
              <Column
                title="Atividade"
                dataIndex="company_type"
                key="company_type"
                width={200}
                sorter={(a, b) => a.company_type && a.company_type.localeCompare(b.company_type)}
                filters={filterCompanyType}
                filterMultiple={false}
                onFilter={(value, record) => record.company_type_id === value}
              />
              <Column
                  title="Aquisição"
                  dataIndex="acquisition"
                  key="acquisition"
                  width={200}
                  sorter={(a, b) => a.acquisition && a.acquisition.localeCompare(b.acquisition)}
                  filters={[{
                    text: 'Nenhum',
                    value: 'Nenhum',
                  }, {
                    text: 'Indicação',
                    value: 'Indicação',
                  },
                  {
                    text: 'Google',
                    value: 'Google',
                  },
                  {
                    text: 'Facebook',
                    value: 'Facebook',
                  },
                  {
                    text: 'Site',
                    value: 'Site',
                  },
                  {
                    text: 'Outros',
                    value: 'Outros',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.acquisition && record.acquisition.indexOf(value) === 0}
              />
               <Column
                title="Aquisição (Obs)"
                dataIndex="indication"
                key="indication"
                width={200}
                sorter={(a, b) => a.indication && a.indication.localeCompare(b.indication)}
              />
              <Column
                title="Societário"
                dataIndex="soc_category"
                key="soc_category"
                width={200}
                render={value => displaySocCategory(value)}
                ellipsis
              />
              <Column
                ellipsis
                title="Sistema de entrega"
                dataIndex="doc_delivery_method"
                key="doc_delivery_method"
                width={200}
                sorter={(a, b) => {
                  const aDeliveryMethodText = get(deliveryMethod, `[${a.doc_delivery_method}].text`);
                  const bDeliveryMethodText = get(deliveryMethod, `[${b.doc_delivery_method}].text`);
                  if (!aDeliveryMethodText) return -1;
                  return aDeliveryMethodText.localeCompare(bDeliveryMethodText);
                }}
                filters={deliveryMethod}
                onFilter={(value, record) => record.doc_delivery_method === value}
                render={value => value !== null && <center><Tag color={get(deliveryMethod, `[${value}].color`)}>{get(deliveryMethod, `[${value}].text`)}</Tag></center>}
              />
              <Column
                ellipsis
                title="Último acesso"
                dataIndex="most_recent_app_user"
                key="most_recent_app_user"
                width={170}
                align="center"
                sorter={(a, b) => {
                  const aLastAccessAt = get(a, 'most_recent_app_user.last_access_at');
                  const bLastAccessAt = get(b, 'most_recent_app_user.last_access_at');

                  return (
                    !aLastAccessAt - !bLastAccessAt ||
                    +(
                      new Date(bLastAccessAt).getTime() >
                      new Date(aLastAccessAt).getTime()
                    ) ||
                    -(
                      new Date(bLastAccessAt).getTime() <
                      new Date(aLastAccessAt).getTime()
                    )
                  );
                }}
                render={(mostRecentAppUser, record) => {
                  const { doc_delivery_method } = record;

                  if (doc_delivery_method !== 2) {
                    return null;
                  }

                  if (!mostRecentAppUser) {
                    return "Não possui acesso";
                  }

                  if (!mostRecentAppUser.last_access_at) {
                    return "Pendente";
                  }

                  return (
                    <center>
                      <Tooltip title={`${mostRecentAppUser.name} - ${moment(mostRecentAppUser.last_access_at).format("DD/MM/YYYY LTS")}`}>
                        {moment(mostRecentAppUser.last_access_at).fromNow()}
                      </Tooltip>
                    </center>
                  )
                }}
              />
              <Column
                  title="CEP"
                  dataIndex="postal_code_formated"
                  key="postal_code_formated"
                  width={200}
                  sorter={(a, b) => a.postal_code && (a.postal_code-b.postal_code)}
                  ellipsis
              />
              <Column
                  title="Rua"
                  dataIndex="street"
                  key="street"
                  width={400}
                  sorter={(a, b) => a.street && a.street.localeCompare(b.street)}
                  ellipsis
              />
              <Column
                  title="Nº"
                  dataIndex="street_number"
                  key="street_number"
                  width={200}
                  sorter={(a, b) => a.street_number && (a.street_number-b.street_number)}
                  ellipsis
              />
              <Column
                  title="Bairro"
                  dataIndex="neighborhood"
                  key="neighborhood"
                  width={200}
                  sorter={(a, b) => a.neighborhood && a.neighborhood.localeCompare(b.neighborhood)}
                  ellipsis
              />
              <Column
                  title="Complemento"
                  dataIndex="complement"
                  key="complement"
                  width={200}
                  sorter={(a, b) => a.complement && a.complement.localeCompare(b.complement)}
                  ellipsis
              />
              <Column
                  title="Cidade"
                  dataIndex="city"
                  key="city"
                  width={200}
                  sorter={(a, b) => a.city && a.city.localeCompare(b.city)}
                  ellipsis
              />
              <Column
                  title="Estado"
                  dataIndex="state"
                  key="state"
                  width={200}
                  sorter={(a, b) => a.state && a.state.localeCompare(b.state)}
                  ellipsis
              />
              <Column
                  title="Faturamento"
                  dataIndex="billing"
                  key="billing"
                  width={200}
                  render={value => toBRL(value)}
                  sorter={(a, b) => a.billing && (a.billing-b.billing)}
                  ellipsis
              />
              <Column
                  title="NFs Entrada"
                  dataIndex="nfs_input"
                  key="nfs_input"
                  width={200}
                  sorter={(a, b) => a.nfs_input && (a.nfs_input-b.nfs_input)}
                  ellipsis
              />
              <Column
                  title="NFs Saida"
                  dataIndex="nfs_output"
                  key="nfs_output"
                  width={200}
                  sorter={(a, b) => a.nfs_output && (a.nfs_output-b.nfs_output)}
                  ellipsis
              />
              <Column
                  title="Lanç. Contábeis"
                  dataIndex="accounting_postings"
                  key="accounting_postings"
                  width={200}
                  sorter={(a, b) => a.accounting_postings && (a.accounting_postings-b.accounting_postings)}
                  ellipsis
              />
              <Column
                  title="Conciliação"
                  dataIndex="conciliation"
                  key="conciliation"
                  width={200}
                  render={(text, record, index) => record.conciliation? 'Sim':''}
                  sorter={(a, b) => a.conciliation && a.conciliation.localeCompare(b.conciliation)}
                  ellipsis
              />
              <Column
                  title="Funcionários"
                  dataIndex="n_staffs"
                  key="n_staffs"
                  width={200}
                  sorter={(a, b) => a.n_staffs && (a.n_staffs-b.n_staffs)}
                  ellipsis
              />
              <Column
                  title="Tipo Funcionários"
                  dataIndex="type_staff"
                  key="type_staff"
                  width={200}
                  sorter={(a, b) => a.type_staff && a.type_staff.localeCompare(b.type_staff)}
                  filters={[{
                    text: 'Honorista',
                    value: 'Honorista',
                  }, {
                    text: 'Mensalista',
                    value: 'Mensalista',
                  },
                  {
                    text: 'Comissionista',
                    value: 'Comissionista',
                  },
                  {
                    text: 'Comissionista Puro',
                    value: 'Comissionista Puro',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.type_staff && record.type_staff.indexOf(value) === 0}
                ellipsis
              />
              <Column
                  title="Honorário de Abertura"
                  dataIndex="first_honorary"
                  key="first_honorary"
                  width={200}
                  render={value => toBRL(value)}
                  sorter={(a, b) => a.first_honorary && (a.first_honorary-b.first_honorary)}
                  ellipsis
              />
              <Column
                  title="Mensalidade"
                  dataIndex="honorary"
                  key="honorary"
                  width={200}
                  render={value => toBRL(value)}
                  sorter={(a, b) => a.honorary && (a.honorary-b.honorary)}
                  ellipsis
              />
              <Column
                ellipsis
                title="Dt. Entrada"
                dataIndex="registered_at"
                key="registered_at"
                width={200}
                sorter={(a, b) => a.registered_at && a.registered_at.localeCompare(b.registered_at)}
                render={key => key && (
                  <Moment format="DD/MM/YYYY">
                    {key}
                  </Moment>
              )}
              />
              {!actived && (
              <Column
                ellipsis
                title="Inativado Em"
                dataIndex="inactived_at"
                key="inactived_at"
                width={200}
                sorter={(a, b) => a.inactived_at && a.inactived_at.localeCompare(b.inactived_at)}
                render={(text, record, index) => record.inactived_at && (
                  <Tooltip
                    placement="bottom"
                    title={
                      record.inactived_by ?
                        `Inativado por ${this.props.findUser(record.inactived_by).name}` :
                        'Inativado automaticamente'
                    }>
                    <Moment format="DD/MM/YYYY">
                      {record.inactived_at}
                    </Moment>
                  </Tooltip>
              )}
              />
              )}
              <Column
                dataIndex="actions"
                key="actions"
                fixed="right"
                width={150}
                render={(text, record, index) => {
                  const showOnBoarding =
                    customerWithoutHonoraries &&
                    record.id === customerWithoutHonoraries.id &&
                    isTenthOnboardStepActive &&
                    !isCustomerDrawerVisible;

                  return (
                    <center key={index}>
                      <Dropdown
                        overlayStyle={{ zIndex: 999 }}
                        overlay={
                          <Menu onClick={(e) => this.onAction(e, record)}>
                            <Menu.Item key="edit" disabled={!canEditCustomers}>
                                <Icon type="edit"></Icon> Editar
                            </Menu.Item>
                            {record.enabled && (
                              <Menu.Item
                                key={!!record.generated_app_user ? 'resendAccess' : 'generateAccess'}
                                disabled={this.checkIfAppAccessIsDisabled(record, canEditCustomers) || showOnBoarding}
                              >
                                <Tooltip title={this.getAppAccessTooltipTitle(record)}>
                                  <Icon type="mail"></Icon> {!!record.generated_app_user ? 'Reenviar acesso' : 'Gerar acesso'}
                                </Tooltip>
                              </Menu.Item>
                            )}
                            {record.inactived_at !== null && (
                              <Menu.Item key="activate" disabled={!canEditCustomers || showOnBoarding}>
                                <Icon type="check"></Icon> Ativar
                              </Menu.Item>
                            )}
                            <Menu.Divider />
                            {record.inactived_at === null && !record.enabled && (
                              <Menu.Item key="force_activate" disabled={!canEditCustomers || showOnBoarding}>
                                <Icon type="check"></Icon> Forçar Ativação
                              </Menu.Item>
                            )}
                            {record.inactived_at === null && (
                              <Menu.Item key="inactivate" disabled={!canEditCustomers || showOnBoarding}>
                                <Icon type="delete"></Icon> Inativar
                              </Menu.Item>
                            )}
                            {record.inactived_at !== null && (
                              <Menu.Item key="delete" disabled={!canDeleteCustomers || showOnBoarding}>
                                <Icon type="delete"></Icon> Excluir
                              </Menu.Item>
                            )}
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <Popover
                          placement="left"
                          content="Clique aqui e edite este cliente"
                          visible={showOnBoarding}
                          overlayStyle={{ zIndex: 1051 }}
                        >
                          <a className="ant-dropdown-link" href="#">
                            Ações <Icon type="down" />
                          </a>
                        </Popover>
                      </Dropdown>
                    </center>
                  )  
                }}
              />
          </Table>
        </div>
        <AppUserAccessModal
          isOpen={this.state.appUserAccessModal.isVisible}
          onClose={() => {
            this.setState({
              appUserAccessModal: {
                isVisible: false,
                customer: null,
              },
            });
          }}
          customer={this.state.appUserAccessModal.customer}
        />
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    customers: state.customers.list.filter(row => row.is_customer === true),
    isLoading: state.customers.isLoading,
    modalInactivate: state.customersnew.modalInactivate,
    isCustomerDrawerVisible: state.customer.drawer.show,
    findUser: (id) => state.users.filter(r=>r.id===id)[0],
    user: state.user,
    onboardingStep: state.onboarding.step,
    isOnboardingActive: state.onboarding.isActive,
    isVideoModalVisible: state.onboarding.videoModal.isVisible,
    companyTypes: state.settings['leads.company_type'],
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadCustomers: () => dispatch(customersActions.loadCustomers()),
    deleteCustomer: (id) => dispatch({
      type: 'DELETE_CUSTOMER',
      payload: {
        request:{
          method: 'delete',
          url:'/customer/'+id,
        }
      },
      id: id
    }),
    inactivateCustomer: (id) => dispatch({
      type: 'INACTIVATE_CUSTOMER',
      payload: {
        request:{
          method: 'put',
          url:'/customer/'+id+'/inactivate',
        }
      },
      id: id
    }),
    activateCustomer: (id) => dispatch({
      type: 'ACTIVATE_CUSTOMER',
      payload: {
        request:{
          method: 'put',
          url:'/customer/'+id+'/activate',
        }
      },
      id: id
    }),
    forceActivateCustomer: (id) => dispatch({
      type: 'FORCE_ACTIVATE_CUSTOMER',
      payload: {
        request:{
          method: 'put',
          url:'/customer/'+id+'/force-activate',
        }
      },
      id: id
    }),
    editCustomer: (data) => dispatch(customersActions.showDrawerCustomer(data)),
    generateAccess: (data) => dispatch(customersActions.generateAccess(data)),
    checkAccess: (data) => dispatch(customersActions.checkAccess(data)),
    finishCurrentOnboardingStep: ({ isActive, step, user }) => {
      finishCurrentOnboardingStepAction({ isActive, dispatch, step, user })
    }
  }
}

export default connect(mapStateToProps, mapDispatchProps)(CustomersList);
import { createAsyncActions } from "~/utils/store";

export const LOAD_MONTHLY_PAYMENTS = createAsyncActions("@@LOAD_MONTHLY_PAYMENTS");
export const GENERATE_MONTHLY_PAYMENTS = createAsyncActions("@@GENERATE_MONTHLY_PAYMENTS");
export const MARK_AS_DEFAULTING = createAsyncActions("@@MARK_AS_DEFAULTING");
export const MARK_AS_PROCESSED = createAsyncActions("@@MARK_AS_PROCESSED");
export const DELETE_HONORARY = createAsyncActions("@@DELETE_HONORARY");

export const loadPayments = ({ filter_by, from, to, status, customer_id, ...params }) => ({ 
  type: LOAD_MONTHLY_PAYMENTS.INIT,
  payload: {
    request: {
      method: "get",
      url: `/monthly/payments?filter_by=${filter_by}&from=${from}&to=${to}&status=${status}&customer_id=${customer_id}`,
      ...params
    },
    filter: {
      from,
      to,
      status,
      filter_by,
      customer_id,
    },
  },
});

export const monthlyGenerate = (data) => ({
  type: GENERATE_MONTHLY_PAYMENTS.INIT,
  payload: {
    request: {
      method: "post",
      url: "/monthly/payments",
      data,
    },
  },
});

export const markAsDefaulting = (id) => ({
  type: MARK_AS_DEFAULTING.INIT,
  payload: {
    request: {
      method: "put",
      url: `/monthly/payments/${id}/defaulting`,
    },
  },
  id,
});

export const markAsProcessed = (id) => ({
  type: MARK_AS_PROCESSED.INIT,
  payload: {
    request: {
      method: "put",
      url: `/monthly/payments/${id}/processed`,
    },
  },
  id,
});

export const deleteHonorary = (id) => ({
  type: DELETE_HONORARY.INIT,
  payload: {
    request: {
      method: "delete",
      url: "/monthly/payments/" + id,
    },
  },
  id,
});
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  ModuleHeader, 
  ModuleHeaderWrapper,
  ButtonsWrapper
} from '~/components/ModuleHeader';
import { Button, Icon } from "antd";

import Layout from "~/components/UI/Layout/Layout";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";

import DrawerReferralProgram from "~/containers/DrawerReferralProgram";
import DrawerReferralIndication from "~/containers/DrawerReferralIndication";

import TableList from "./TableList/index";

import * as drawerActions from "~/containers/DrawerReferralProgram/actions";
import * as campaignsActions from "./actions";

export const ReferralProgram = () => {
  const dispatch = useDispatch();

  const campaigns = useSelector((state) => state.referrallProgramCampaigns.data);
  const isLoading = useSelector((state) => state.referrallProgramCampaigns.isLoading);

  useEffect(() => {
    dispatch(campaignsActions.all());
  }, []);

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
        <Layout>
          <div>
            <ModuleHeaderWrapper>
              <ModuleHeader
                  breadcrumbs={['Plugins', 'Indicação Premiada']}
                  title={'Indicação Premiada'}
                  actions={
                    <Button
                      type="primary"
                      onClick={() => {
                          dispatch(drawerActions.show());
                      }}
                  >
                      <Icon type="folder-add" /> Nova Campanha
                  </Button>
                  }
              />
            </ModuleHeaderWrapper>
            <TableList 
              campaigns={campaigns}
            />
            <DrawerReferralProgram />
            <DrawerReferralIndication />
          </div>
        </Layout>
    </div>
  );
};

export default ReferralProgram;
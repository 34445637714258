import React, { useState, useEffect } from "react";
import { Form, Input, Modal } from "antd";
import { useDispatch } from "react-redux";

import * as pluginsActions from "~/containers/Plugins/actions";
import { Acquisitions, LinkGroups } from "~/components/SelectWithAdd";
import Tip from "~/components/Tip";

export const LinkModal = Form.create()(
  ({ isVisible, onClose, setLinks, form, data}) => {
    const { getFieldDecorator, validateFields, setFieldsValue } = form;

    const dispatch = useDispatch();

    const [isSavingLink, setIsSavingLink] = useState(false);

    useEffect(() => {
      if (data) {
        setFieldsValue({ acquisition_id: data.acquisition_id });
        setFieldsValue({ url: data.url });
        setFieldsValue({ group_id: data.group_id });
      }
    }, [data]);


    function saveLink() {
      validateFields(async (error, values) => {
        if (error) {
          return;
        }

        setIsSavingLink(true);

        // If data.id exists, it means that the user is editing a link
        if(data.id){
          values.id = data.id;
          try {
            const result = await dispatch(pluginsActions.updateCountClick({ data: values }));
            setLinks((linksInState) => linksInState.map((row) => (row.id === data.id ? result.payload.data.data : row)));
            onClose();
          } catch (error) {
            console.log(error);
          } finally {
            setIsSavingLink(false);
          }
          return;
        }

        // If data.id does not exist, it means that the user is adding a new link
        try {
          const result = await dispatch(pluginsActions.createCountClick({ data: values }));
          setLinks((linksInState) => [...linksInState, result.payload.data.data]);

          onClose();
        } catch (error) {
          console.log(error);
        } finally {
          setIsSavingLink(false);
        }
      });
    }

    return (
      <Modal
        destroyOnClose
        title={data.id ? "Editar Link" : "Adicionar Link"}
        visible={isVisible}
        onCancel={onClose}
        onOk={saveLink}
        okText={data.id ? "Salvar" : "Adicionar"}
        okButtonProps={{ loading: isSavingLink }}
      >
        <Form>
          <Form.Item label={<span>Grupo <Tip>Visualize os relatórios de links por grupos</Tip></span>}>
            {getFieldDecorator("group_id")(
              <LinkGroups />
            )}
          </Form.Item>
          <Form.Item label={<span>Origem <Tip>Origem é onde você pretende compartilhar este link, exemplo: Seu site, Blog, Instagram etc... <br /><br/> Dica: Se a origem desejada não estiver na lista, adicione digitando no campo e em seguida clique em cadastrar.</Tip></span>} required>
            {getFieldDecorator("acquisition_id")(
              <Acquisitions />
            )}
          </Form.Item>
          <Form.Item label="Link" required>
            {getFieldDecorator("url", {
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o link",
                },
                {
                  type: "url",
                  message: "Por favor, informe um link válido",
                },
              ],
            })(<Input placeholder="Informe o link" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FileDownload from 'js-file-download';
import { request } from '~/components/Instance';
import { Table, Tag, Icon, Dropdown, Menu, Button, Input, Modal, Tooltip, Statistic, Row, Col, Card, message, Radio } from 'antd';
import Moment from 'react-moment';
import { toBRL } from '~/components/UI/InputCurrency';
import Download from '~/components/Components/Download';
import * as IRPFActions from '~/containers/IRPF/actions';
import moment from 'moment';

import * as drawerIRPFActions from '~/containers/DrawerIRPF/actions';

import { YearPicker } from '~/components/UI/YearPicker';

import { PERMISSIONS } from '~/constants/permissions.constants';
import { ModuleHeader, Header } from '~/components/ModuleHeader';
import { HeaderContainer, HeaderLeftContent, NewIrpfContainer, SearchContainer, WhatsappLink } from './styles';
import { WhatsappIcon } from '~/icons/WhatsappIcon';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;
const Search = Input.Search;

const getTagInfo = (status) => ({
  0: {
    text: 'Pendente',
    color: '#f5222d'
  },
  2: {
    text: 'Em processo',
    color: '#fadb14'
  },
  1: {
    text: 'Concluido',
    color: '#a0d911'
  }
}[status])

const List = (props) => {

  const [search, setSearch] = useState('');
  const [payment, setPayment] = useState({
    paid: 0,
    pendent: 0
  })
  const [showActive, setShowActive] = useState(true);

  const {
    showDrawer,
    customers,
    // deleteCustomer,
    archiveCustomer,
    activateCustomer,
    user,
    currentYear,
    changeYear,
    isLoading
  } = props;

  useEffect(() => {
    const honorariesOfCurrentYear = customers
      .filter((customer) => !customer.archived_at && !customer.archived_by)
      .flatMap((customer) => customer.honoraries)
      .filter((honorary) => honorary.year === currentYear.year());

    const paid = honorariesOfCurrentYear.reduce((total, honorary) => {
      return !honorary.paid_at ? total : total + honorary.amount;
    }, 0);

    const pendent = honorariesOfCurrentYear.reduce((total, honorary) => {
      return !!honorary.paid_at ? total : total + honorary.amount;
    }, 0);

    setPayment({
      paid,
      pendent
    })
  }, [customers]);

  const onAction = (e, customer) => {
    switch(e.key){
      case 'edit': 
      showDrawer(customer);
      break;
      case 'archive': 
        Modal.confirm({
          title: 'Você realmente deseja arquivar este cliente?',
          content: 'Esta ação poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => archiveCustomer(customer.id, currentYear.year())
        });
      break;
      case 'activate': 
          activateCustomer(customer.id, currentYear.year())
      break;
      default:
        return null;
    }
  }
  

  const getFilename = (data) => {
    const now = moment().format('YYYY-MM-DD');
    return `${data.name}_IRPF_${now}.pdf`;
  }

  const copyReceiptToClipBoard = (receipt) => {
    navigator.clipboard.writeText(receipt.replace(/\D/g,''));
    message.success('Recibo copiado para a área de transferência!');
  }

  const handleExportToExcel = async () => {
    try {
      const response = await request('POST', `/pf/export/excel?year=${currentYear.year()}`, {}, true, {
        responseType: 'blob'
    })
      FileDownload(response.data, `IRPF_${currentYear.year()}.xlsx`);
    } catch (error) {
      message.error('Não foi possível realizar a exportação!')
    }
  }

  const filtered = (customers !== null)? customers.filter((str) => {

    if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    if(str.origem && str.origem.toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    return false;
  }).map((customer) => {
    const honoraryOfCurrentYear = customer.honoraries.find((honorary) => Number(honorary.year) === currentYear.year());
    return {
      ...customer,
      honorary_of_current_year: honoraryOfCurrentYear
    }
  }).sort((a, b) => a.name.localeCompare(b.name)) : [];

  const activeCustomers = filtered.filter((customer) => !customer.archived_at && !customer.archived_by);

  const archivedCustomers = filtered.filter((customer) => !!customer.archived_at && !!customer.archived_by);

  const canSee = user.permissions.includes(PERMISSIONS.irpf.summary.view);

  //fix for widescreens
  const additional = window.innerWidth > 1440 ? 200:0;

  return (
    <div>
      <HeaderContainer>
        <ModuleHeader
          breadcrumbs={['Tarefas', 'IRPF']} 
          search={
            <SearchContainer>
              <Search 
                placeholder="Pesquisar cliente"
                onChange={e => setSearch(e.target.value)}
              />
              <Radio.Group 
                value={showActive}
                style={{display: 'flex'}}
                onChange={(event) => setShowActive(event.target.value)}>
                <Radio.Button value={true} style={showActive ? {borderColor: `#a0d911`, color: `#a0d911`}:{}}>Ativos</Radio.Button>
                <Radio.Button value={false} style={!showActive ? {borderColor: `#f5222d`, color: `#f5222d`, boxShadow: `-1px 0 0 0 #f5222d`}:{}}>Arquivados</Radio.Button>
              </Radio.Group>
            </SearchContainer>
          }
          actions={
            <NewIrpfContainer>
              <YearPicker
                value={currentYear}
                onChange={(value) => {
                  changeYear(value);
                  props.loadIRPFs(value.year());
                }}
                allowClear={false}
                allowedYear={moment().year()}
              />
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={showDrawer}>
                      Cadastrar Cliente
                    </Menu.Item>
                    <Menu.Item key="2" onClick={handleExportToExcel}>
                      Exportar para arquivo CSV (excel)
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="primary" icon="menu">
                  Ações
                </Button>
              </Dropdown>
            </NewIrpfContainer>
          }
        />
      </HeaderContainer>
        
        {canSee && (
        <Row gutter={16}>
          <Col span={4}>
            <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
              <Statistic title="Total" value={payment.paid + payment.pendent} precision={2} formatter={(v) => toBRL(v)} />
              {/* <Statistic title={<small>2020</small>} value={sum(filtered, 'honorary_2020')} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} /> */}
            </Card>
          </Col>
          <Col span={4}>
            <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
              <Statistic title="Pago" value={payment.paid} precision={2} valueStyle={{ color: '#52c41a' }} formatter={(v) => toBRL(v)} />
            </Card>
          </Col>
          <Col span={4}>
            <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
              <Statistic title="Pendente" value={payment.pendent} precision={2} valueStyle={{ color: '#f5222d' }} formatter={(v) => toBRL(v)} />
            </Card>
          </Col>
        </Row>
        )}

        <div className="table-clabs">
          <Table 
            dataSource={showActive ? activeCustomers : archivedCustomers}
            rowKey="id"
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.irpfs
            })}
            loading={isLoading}
            bordered={true}
            scroll={{ x: (1880+additional), y: `${window.innerHeight-175}px`}}
          >
            <Column
                title="Cód"
                dataIndex="id"
                key="id"
                fixed="left"
                width={150}
                render={id => <center><Tag key={id}>#IRPF-{id}</Tag></center>}
              />
              <Column
                  title="Status"
                  dataIndex="honorary_of_current_year"
                  align="center"
                  key="status_text"
                  width={150}
                  fixed="left"
                  render={(honoraryOfCurrentYear) => {
                    if (!honoraryOfCurrentYear) return null;

                    if (!honoraryOfCurrentYear.created_at) {
                      return <Tag color="#595959">Não iniciado</Tag>
                    }

                    const { text, color } = getTagInfo(honoraryOfCurrentYear.process_step);
                    return <Tag color={color}>{text}</Tag>
                  }}
                  filters={[{
                    text: 'Pendente',
                    value: 0,
                  },{
                    text: 'Em processo',
                    value: 2,
                  }, {
                    text: 'Concluido',
                    value: 1,
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.honorary_of_current_year.process_step === value}
              />
              <Column
                  title="Origem"
                  dataIndex="origem"
                  key="origem"
                  fixed="left"
                  width={200}
                  sorter={(a, b) => a.origem && a.origem.localeCompare(b.origem)}
              />
              <Column
                  title="Nome"
                  dataIndex="name"
                  key="name"
                  fixed="left"
                  width={300+additional}
                  sorter={(a, b) => a.name && a.name.localeCompare(b.name)}
              />
              <Column
                  title="Data de Nascimento"
                  dataIndex="birth_date"
                  render={birth_date => birth_date && <Moment format="DD/MM/YYYY">{birth_date}</Moment>}
                  sorter={(a, b) => a.birth_date && a.birth_date.localeCompare(b.birth_date)}
                  key="birth_date"
                  width={150}
              />
              <Column
                  title="CPF"
                  dataIndex="doc_formated"
                  key="doc_formated"
                  width={150}
                  render={(text) => (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(text.replace(/[^0-9]/g,''));
                        message.destroy();
                        message.success('CPF copiado para a área de transferência');
                      }}
                    >
                      {text}
                    </span>
                  )}
              />
              <Column
                title="Acesso GOV"
                dataIndex="e_cac"
                key="e_cac"
                width={200}
                render={(text) => (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigator.clipboard.writeText(text);
                      message.destroy();
                      message.success('Acesso GOV copiado para a área de transferência');
                    }}
                  >
                    {text}
                  </span>
                )}
              />
              <Column
                title="Senha"
                dataIndex="password"
                key="password"
                width={200}
                render={(text) => (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigator.clipboard.writeText(text);
                      message.destroy();
                      message.success('Senha copiada para a área de transferência');
                    }}
                  >
                    {text}
                  </span>
                )}
              />
              <Column
                title={`Recibo ${currentYear.year()}`}
                key="receipt_of_current_year"
                dataIndex="receipt_number"
                width={180}
                render={(receiptNumber) => {
                  if (!receiptNumber) return null;

                  return (
                    <>
                      <span>{receiptNumber}</span>
                      <Icon type="copy" onClick={() => copyReceiptToClipBoard(receiptNumber)} style={{ marginLeft: 4 }} />
                    </>
                  )
                }}
              />
              <Column
                title={`Recibo ${currentYear.year() - 1}`}
                key="receipt_of_last_year"
                dataIndex="receipt_number_of_last_year"
                width={120}
                render={(receiptNumber) => {
                  if (!receiptNumber) return null;

                  return (
                    <>
                      <span>{receiptNumber}</span>
                      <Icon type="copy" onClick={() => copyReceiptToClipBoard(receiptNumber)} style={{ marginLeft: 4 }} />
                    </>
                  )
                }}
              />
              <Column
                  title="Email"
                  dataIndex="email"
                  key="email"
                  width={250}
                  render={(text) => (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(text);
                        message.destroy();
                        message.success('Email copiado para a área de transferência');
                      }}
                    >
                      {text}
                    </span>
                  )}
              />
              <Column
                  title="WhatsApp"
                  dataIndex="phone_formated"
                  key="phone_formated"
                  width={200}
                  render={(text, record, index) => record.mobile_phone && record.mobile_phone.length > 10 ? (
                  <Tooltip placement="bottom" title={`Clique para ir para a conversa no Whatsapp`}>
                    <WhatsappLink href={`https://wa.me/55${record.mobile_phone}`} target="_blank">
                      <WhatsappIcon /> {record.phone_formated}
                    </WhatsappLink>
                  </Tooltip>
              ): record.phone_formated}
              />
              {/* <Column
                  title="Itens do IR"
                  dataIndex="items"
                  key="items"
                  width={200}
              /> */}
              <Column
                  title="Numero do Beneficio"
                  dataIndex="benefit_number"
                  key="benefit_number"
                  width={180}
                  render={(text) => (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(text);
                        message.destroy();
                        message.success('Número do benefício copiado para a área de transferência');
                      }}
                    >
                      {text}
                    </span>
                  )}
              />
              <Column
                  title={`Honorário ${currentYear.year() - 1}`}
                  key="honorary_last_year"
                  width={180}
                  render={(_, record) => {
                    const honoraryOfLastYear = record.honoraries.find((honorary) => honorary.year === currentYear.year() - 1);
                    if (!honoraryOfLastYear) return `R$ 0.00`;
                    return new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(honoraryOfLastYear.amount)
                  }}
              />
              <Column
                  title={`Honorário ${currentYear.year()}`}
                  dataIndex="honorary_of_current_year"
                  key="honorary_current_year"
                  width={180}
                  render={(honoraryOfCurrentYear) => {
                    if (!honoraryOfCurrentYear) return `R$ 0.00`;
                    return new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(honoraryOfCurrentYear.amount)
                  }}
              />
              <Column
                  title="Status Pgto"
                  dataIndex="honorary_of_current_year"
                  key="payment_status"
                  width={150}
                  render={(honoraryOfCurrentYear) => {
                    if (!honoraryOfCurrentYear) return null;

                    return (
                      <Tag color={honoraryOfCurrentYear.payment_status === 'Pago' ? 'green' : 'red'}>{honoraryOfCurrentYear.payment_status}</Tag>
                    );
                  }}
                  filters={[{
                    text: 'Pendente',
                    value: 'Pendente',
                  },{
                    text: 'Pago',
                    value: 'Pago',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.honorary_of_current_year.payment_status.indexOf(value) >= 0}
              />
              <Column
                title="Bloco de notas"
                dataIndex="notes"
                key="notes"
                width={200}
              />
              <Column
                  dataIndex="actions"
                  key="actions"
                  fixed="right"
                  width={100}
                  render={(text, record, index) => {
                    const honoraryOfCurrentYear = record.honorary_of_current_year;

                    return (
                    <center key={index}>
                        <Dropdown overlay={
                            <Menu onClick={(e) => onAction(e, record)}>
                              <Menu.Item key="edit">
                                  <Icon type="edit"></Icon> Editar
                              </Menu.Item>
                              <Menu.Item disabled={honoraryOfCurrentYear ? !honoraryOfCurrentYear.created_at : false}>
                                {honoraryOfCurrentYear && !honoraryOfCurrentYear.created_at ? (
                                    <Tooltip title="Para imprimir é necessário iniciar o atendimento">
                                      <Icon
                                        style={{
                                          minWidth: '12px',
                                          marginRight: '8px',
                                          fontSize: '12px'
                                        }}
                                        type="printer"
                                      />
                                      <span>Imprimir</span>
                                    </Tooltip>
                                ) : (
                                  <Download url={`/pf/customer/${record.id}/print?year=${currentYear.year()}`} filename={getFilename(record)}>
                                    <div><Icon style={{
                                      minWidth: '12px',
                                      marginRight: '8px',
                                      fontSize: '12px'
                                    }} type="printer" ></Icon> Imprimir</div>
                                  </Download>
                                )}
                              </Menu.Item>
                              <Menu.Divider />
                              {showActive ? (
                                <Menu.Item key="archive"><Icon type="delete"></Icon> Arquivar</Menu.Item>
                              ) : (
                                <Menu.Item key="activate"><Icon type="check-circle"></Icon> Ativar</Menu.Item>
                              )}
                            </Menu>
                        } trigger={['click']}>
                            <a className="ant-dropdown-link" href="#">
                            Ações <Icon type="down" />
                            </a>
                        </Dropdown>
                    </center>
                    )
                  }}
              />
          </Table>
        </div>
      </div>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  customers: state.irpf.customers,
  currentYear: state.irpf.current_year,
  isLoading: state.irpf.isLoading,
  findUser: (id) => state.users.filter(r=>r.id===id)[0],
});

const mapDispatchProps = dispatch => ({
  showDrawer: (data = null) => dispatch(drawerIRPFActions.show(data)),
  // deleteCustomer: (data) => dispatch(drawerIRPFActions.remove(data)),
  loadIRPFs: (year) => dispatch(IRPFActions.loadIRPFs({ year })),
  archiveCustomer: (honorary_id, year) => dispatch(drawerIRPFActions.archive(honorary_id, year)),
  activateCustomer: (honorary_id, year) => dispatch(drawerIRPFActions.activate(honorary_id, year)),
  changeYear: (year) => dispatch(IRPFActions.changeYear(year)),
});

export default connect(mapStateToProps, mapDispatchProps)(List);
import React, { useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Divider, Icon } from 'antd';
import * as settingActions from "~/containers/Settings/actions";

const SelectWithAdd = forwardRef((props) => {
    const [search, setSearch] = useState('');

    return (
        <Select
            {...props}
            showSearch
            placeholder={props.placeholder ? props.placeholder : 'Selecione'}
            optionFilterProp="children"
            onChange={props.onChange ? props.onChange : () => {}}
            onSearch={(val) => setSearch(val)}
            dropdownRender={menu => (
                <div>
                    {menu}
                    {search && (
                        <div
                            onMouseDown={e => e.preventDefault()}
                            onClick={() => {
                                if(props.registerItem){
                                    props.registerItem(search);
                                }
                                setSearch('');
                            }}
                        >
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ padding: '8px', cursor: 'pointer' }}>
                                <Icon type="plus" /> Cadastrar <b>{search}</b>
                            </div>
                        </div>
                    )}
                </div>
            )}
        >
            {props.children}
        </Select>
    );
});

export default SelectWithAdd;

export const Acquisitions = (props) => {
    const settingsAcquisition = useSelector((state) => state.settings["leads.acquisition"]);
    const dispatch = useDispatch();

    return (
        <SelectWithAdd
            {...props}
            placeholder="Selecione a origem"
            registerItem={(value) => dispatch(settingActions.createSetting({ data: { key: "leads.acquisition", value }}))}
        >
            {settingsAcquisition.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                    {item.value}
                </Select.Option>
            ))}
        </SelectWithAdd>
    );
};

export const LinkGroups = (props) => {
    const data = useSelector((state) => state.settings["links.group_id"]);
    const dispatch = useDispatch();

    return (
        <SelectWithAdd
            {...props}
            placeholder="Selecione o grupo"
            registerItem={(value) => dispatch(settingActions.createSetting({ data: { key: "links.group_id", value }}))}
        >
            {data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                    {item.value}
                </Select.Option>
            ))}
        </SelectWithAdd>
    );
};

export const LostReasons = (props) => {
    const settingsLostReasons = useSelector((state) => state.settings["leads.lost_reason"]);
    const dispatch = useDispatch();

    return (
        <SelectWithAdd
            {...props}
            placeholder="Selecione o motivo"
            registerItem={(value) => dispatch(settingActions.createSetting({ data: { key: "leads.lost_reason", value }}))}
        >
            {settingsLostReasons.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                    {item.value}
                </Select.Option>
            ))}
        </SelectWithAdd>
    );
}

export const CompanyTypes = (props) => {
    const settingsCompanyTypes = useSelector((state) => state.settings["leads.company_type"]);
    const dispatch = useDispatch();

    return (
        <SelectWithAdd
            {...props}
            placeholder="Selecione a atividade da empresa"
            registerItem={(value) => dispatch(settingActions.createSetting({ data: { key: "leads.company_type", value }}))}
        >
            {settingsCompanyTypes.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                    {item.value}
                </Select.Option>
            ))}
        </SelectWithAdd>
    );
}

export const Tags = (props) => {
    const tags = useSelector((state) => state.settings["leads.tags"]);
    const dispatch = useDispatch();

    return (
        <SelectWithAdd
            {...props}
            mode="tags"
            placeholder="Selecione uma ou mais tags"
            registerItem={(value) => dispatch(settingActions.createSetting({ data: { key: "leads.tags", value }}))}
        >
            {tags.map((item) => (
                <Select.Option key={item.id} value={item.value}>
                    {item.value}
                </Select.Option>
            ))}
        </SelectWithAdd>
    );
};

export const OrgNames = (props) => {
    const orgNames = useSelector((state) => state.settings["customers.org_name"]);
    const dispatch = useDispatch();

    return (
        <SelectWithAdd
            {...props}
            placeholder="Selecione o nome do conselho"
            registerItem={(value) => dispatch(settingActions.createSetting({ data: { key: "customers.org_name", value }}))}
        >
            {orgNames.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                    {item.value}
                </Select.Option>
            ))}
        </SelectWithAdd>
    );
}
import { Breadcrumb } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7px;
`;

export const StyledBreadcrumb = styled(Breadcrumb).attrs({
  style: {
    display: "inline-blick",
  },
})``;

export const Title = styled.span`
  font-size: 22px;
  color: #000;
  white-space: nowrap;
`;

export const Subtitle = styled.small`
  font-size: 70%;
  opacity: 0.6;
`;
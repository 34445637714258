import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Button, Icon } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Scrollbar from 'react-scrollbars-custom';
import 'moment/locale/pt-br';
import { ModuleHeader } from '~/components/ModuleHeader';
import { ButtonsWrapper, HeaderContainer, LoadingSkeleton, LoadingSkeletonContainer } from './styles';
import { getListStyle } from './helpers';
import ItemCard from './components/ItemCard';
import * as drawerBoardActions from '~/containers/DrawerBoardItem/actions';
import * as boardActions from './actions';

const Board = ({
  isLoading
}) => {
  const dispatch = useDispatch();
  const boards = useSelector(state => state.boards);
  const board = boards.data ? boards.data[1] : null;
  const lists = board ? board.lists : {};

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.source.droppableId === result.destination.droppableId) {
      // dispatch(boardActions.reorderList(result));
    } else {
      dispatch(boardActions.moveItem(result));
    }
  };

  return (
    <div>
      <div style={{
        margin: `0px -16px`,
        padding: '15px',
        background: '#fff',
        marginBottom: `10px`
      }}>
          <ModuleHeader
            breadcrumbs={[board.name]}
            title={board.name}
            action={
              <Button type="primary" onClick={() => dispatch(drawerBoardActions.show())}>
                <Icon type="folder-add" /> Item
              </Button>
            }
          />
      </div>
      <Row gutter={16}>
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          {Object.keys(lists).map((listId, index) =>
            <Col span={8} key={index}>
              <Droppable droppableId={lists[listId].uid} key={listId}>
                {(provided, snapshot) => (
                  <Card
                    title={lists[listId].name}
                    bodyStyle={{ padding: 0 }}
                    headStyle={lists[listId].style}
                  >
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver, lists[listId].style)}
                      {...provided.droppableProps}
                    >
                      <Scrollbar style={{ height: (window.innerHeight - 240) }}>
                        {isLoading
                          ? Array(5)
                            .fill(1)
                            .map((_, index) => (
                              <LoadingSkeletonContainer key={index}>
                                <LoadingSkeleton />
                              </LoadingSkeletonContainer>
                            ))
                          : lists[listId].cards.map((item, index) => {
                            return (
                              <Draggable
                                key={item.uid}
                                draggableId={item.uid}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    item={item}
                                    onClick={(event) => {
                                      if (event.target.tagName !== 'LI') {
                                        dispatch(drawerBoardActions.show(item));
                                      }
                                    }}
                                  >
                                    <ItemCard 
                                      item={item} 
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </Scrollbar>
                    </div>
                  </Card>
                )}
              </Droppable>
            </Col>
          )}
        </DragDropContext>
      </Row>
    </div>
  );
};

export default Board;

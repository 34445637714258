import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Dropdown, Menu, Modal, Button, Input, Tooltip, Radio } from 'antd';
import FileDownload from 'js-file-download';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import DownloadBatch from '../Components/DownloadBatch';
import Download from '../Components/Download';

import { toBRL } from '../UI/InputCurrency';
import AdvancedFilter from './Components/AdvancedFilter';
import * as billsToReceiveActions from '~/components/BillsToReceive/actions';

import {
  Cod,
  Status
} from '~/components/UI';
import { PERMISSIONS } from '~/constants/permissions.constants';
import {
  DropdownButton,
  HeaderButtonsWrapper,
  HeaderContainer,
  HeaderLeftContent,
  NotRevisedRadioButton,
  RevisedRadioButton,
} from './styles';
import { request } from '../Instance';
import { ModuleHeader } from '../ModuleHeader';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;
const Search = Input.Search;

const payment_method = (val) =>{
  switch(val){
    case 1: return 'Dinheiro';
    case 2: return 'Boleto';
    case 3: return 'Cartão';
    case 4: return 'Transferência';
  }
}

class CustomersList extends Component {
  state = {
    month: moment(),
    search: '',
    actived: 1,
    filter_by: "due_date",
    from: moment().format("YYYY-MM-01"),
    to: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
    status: "all",
    selectedRowKeys: [],
    extraStatus: 'revised'
  }

  onAction = (e, bill) => {
    switch(e.key){
      case 'edit':
        this.props.openBillForm(bill.extra);
      break;
      case 'paid': 
        Modal.confirm({
          title: 'Você realmente deseja alterar este pagamento para pago?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.setPaid(bill.id).then(response => this.props.loadExtras(this.props.filter))
        });
      break;
      case 'inadimplente':
        Modal.confirm({
          title: 'Você realmente deseja marcar como inadimplente?',
          content: 'Você poderá alterar isso mais tarde.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.changeStatus(bill.id, 'Inadimplente').then(response => this.props.loadExtras(this.props.filter))
        });
      break;
      case 'ajuizado':
        Modal.confirm({
          title: 'Você realmente deseja marcar como ajuizado?',
          content: 'Você poderá alterar isso mais tarde.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.changeStatus(bill.id, 'Ajuizado').then(response => this.props.loadExtras(this.props.filter))
        });
      break;
    }
  }

  getFilename = (data) => {  
    const now = moment(data.apuration_at).format('MM-YYYY');
    return `${data.customer.name}_MENSALIDADE_${now}.pdf`;
  } 

  onSelectedRowKeysChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  exportToExcel = () => {
    const filter = this.state.selectedRowKeys.length > 0
      ? { ids: this.state.selectedRowKeys }
      : {
          filters: {
            filter_by: this.state.filter_by,
            from: this.state.from,
            to: this.state.to,
            status: this.state.status,
          }
      };

    request(
      "POST",
      "extras/payments/export/excel",
      filter,
      true,
      { responseType: 'blob' }
    )
    .then(response => {
        const now = moment().format('YYYY-MM-DD_hh-mm-ss');
        FileDownload(response.data, `Servicos_extras_${now}.xlsx`);
    });
  }

  render() {
    const { extras, user, isLoading } = this.props;
    const { search, selectedRowKeys } = this.state;

    let filtered = (extras !== null)? extras.filter((str) => {

      if(str.customer.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if(str.customer.cod !== null && str.customer.cod.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      return false;
    }): [];

    const revisedExtras = filtered.filter(({ extra }) => extra.revised);
    const notRevisedExtras = filtered.filter(({ extra }) => !extra.revised);

    return (  
      <div>
        <div style={{ 
          margin: `0px -16px`, 
          padding: '15px', 
          background: '#fff', 
          marginBottom: `10px` }}>
            <ModuleHeader 
              breadcrumbs={['Financeiro', 'Serviços extras']} 
              title="Serviços Extras"
              search={
                <Search 
                  placeholder="Pesquisar por cód ou cliente" 
                  onChange={e => this.setState({search: e.target.value})}
                  style={{ maxWidth: 300 }}
                />
              }
              actions={
                <>
                  <Radio.Group
                    value={this.state.extraStatus}
                    onChange={({ target }) =>
                      this.setState({ extraStatus: target.value })
                    }
                  >
                    <RevisedRadioButton
                      isActive={this.state.extraStatus === "revised"}
                    >
                      Revisados ({ revisedExtras.length })
                    </RevisedRadioButton>
                    <NotRevisedRadioButton
                      isActive={this.state.extraStatus === "not_revised"}
                    >
                      Não revisados ({ notRevisedExtras.length })
                    </NotRevisedRadioButton>
                  </Radio.Group>
                  <Dropdown
                    overlay={
                      <Menu>
                        {selectedRowKeys.length > 0 && (
                          <Menu.Item
                            key="pdf"
                          >
                            <DownloadBatch
                              url="/extras/payments/print/batch"
                              filename="servico-extra.zip"
                              ids={selectedRowKeys}
                            >
                              <DropdownButton>PDF</DropdownButton>
                            </DownloadBatch>
                          </Menu.Item>
                        )}
                        <Menu.Item
                          key="csv"
                          onClick={this.exportToExcel}
                        >
                          CSV (Excel)
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Button type="primary" icon="download">
                      Exportar
                    </Button>
                  </Dropdown>
                  <Button
                    type="primary"
                    onClick={() => this.props.openBillForm({})}
                  >
                    Cadastrar Conta
                  </Button>
                </>
              }
              advanced={
                <AdvancedFilter
                  setFilters={(filters) => {
                    this.setState(filters)
                  }}
                />
              }
            />
        </div>
        <div className="table-clabs">
          <Table 
            rowKey="id"
            loading={isLoading}
            dataSource={
              this.state.extraStatus === "revised"
                ? revisedExtras
                : notRevisedExtras
            }
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.billsToReceive
            })}
            bordered={true}
            rowClassName={(record, index) => `${record.extra.revised? 'row-green':'row-red'}`}
            scroll={{ x: 1890, y: `${window.innerHeight-175}px`}}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectedRowKeysChange,
            }}
          >
             <Column
                  title="Status"
                  dataIndex="status"
                  key="status"
                  width={120}
                  fixed="left"
                  render={val => <Status value={val}/>}
                  filters={[{
                    text: 'Pago',
                    value: 'Pago'
                  }, {
                    text: 'Pendente',
                    value: 'Pendente'
                  },
                  {
                    text: 'Inadimplente',
                    value: 'Inadimplente'
                  },
                  {
                    text: 'Ajuizado',
                    value: 'Ajuizado'
                  },
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.status && record.status.indexOf(value) === 0}
              />
              <Column
                  title="Empresa"
                  dataIndex="customer.name"
                  key="name"
                  fixed="left"
                  width={400}
                  render={(val, record) => {
                    const customerName = record.customer.name;

                    if (customerName.length > 36) {
                      return <span>{record.customer.cod} - <Tooltip title={customerName}>{customerName.slice(0, 36)}...</Tooltip></span> 
                    }

                    return <span>{record.customer.cod} - {record.customer.name}</span>
                  }}
              />
              <Column
                  title="Tarefa"
                  dataIndex="extra.task_name"
                  key="task"
                  width={320}
              />
              <Column
                  title="Parcela"
                  dataIndex="quota"
                  key="quota"
                  width={100}
              />
              <Column
                  title="Método"
                  dataIndex="method"
                  key="method"
                  width={100}
                  filters={[{
                    text: payment_method(1),
                    value: 1,
                  }, {
                    text: payment_method(2),
                    value: 2,
                  },
                  {
                    text: payment_method(3),
                    value: 3,
                  },
                  {
                    text: payment_method(4),
                    value: 4,
                  }
                ]}
                filterMultiple={false}
                render={val => payment_method(val)}
                onFilter={(value, record) => record.type_staff && record.type_staff.indexOf(value) === 0}
              />
              <Column
                  title="Apuração"
                  dataIndex="extra.apuration_at"
                  key="extra.apuration_at"
                  width={150}
                  sorter={(a, b) => a.apuration_at && a.apuration_at.localeCompare(b.apuration_at)}
                  render={apuration_at => 
                    <center>
                      <Moment format="DD/MM/YYYY">
                        {apuration_at}
                      </Moment>
                    </center>
                  }
              />
              <Column
                  title="Vencimento"
                  dataIndex="due_date"
                  key="due_date"
                  width={150}
                  sorter={(a, b) => a.due_date && a.due_date.localeCompare(b.due_date)}
                  render={due_date => 
                    <center>
                      <Moment format="DD/MM/YYYY">
                        {due_date}
                      </Moment>
                    </center>
                  }
              />
              <Column
                  title="Valor"
                  dataIndex="amount"
                  key="amount"
                  width={200}
                  sorter={(a, b) => a.amount && (a.amount-b.amount)}
                  render={value => toBRL(value)}
              />
              <Column
                  title="Societário"
                  dataIndex="extra.dash_task_id"
                  key="extra.dash_task_id"
                  width={150}
                  render={value => value? <Cod>#SOC{value}</Cod>: ''}
              />

              <Column
                  dataIndex="actions"
                  key="actions"
                  fixed="right"
                  width={150}
                  render={(text, record, index) => (
                  <center key={index}>
                    <Dropdown overlay={
                        <Menu onClick={(e) => this.onAction(e, record)}>
                          {user.permissions.includes(PERMISSIONS.extraServices.edit) ?
                          <Menu.Item key="edit">
                              <Icon type="edit"></Icon> Editar
                          </Menu.Item>
                          :
                          <Menu.Item key="edit">
                              <Icon type="file"></Icon> Detalhes
                          </Menu.Item>
                          }
                          {record.paid_at === null && (<Menu.Divider />)}
                          {record.paid_at === null && (
                          <Menu.Item key="paid">
                            <Icon type="check"></Icon> Marcar como Pago
                          </Menu.Item>
                          )}
                          {record.status !== 'Pago' && record.status !== 'Inadimplente' && (
                          <Menu.Item key="inadimplente">
                            <Icon type="exclamation-circle"></Icon> Marcar como inadimplente
                          </Menu.Item>
                          )}
                          {record.status !== 'Pago' && record.status !== 'Ajuizado' && (
                          <Menu.Item key="ajuizado">
                            <Icon type="audit"></Icon> Marcar como ajuizado
                          </Menu.Item>
                          )}
                          {record.paid_at === null && (<Menu.Divider />)}
                          <Menu.Item>
                            <Download url={`/extras/payments/print/${record.id}`} filename={this.getFilename(record)}>
                              <div><Icon style={{
                                    minWidth: '12px',
                                    marginRight: '8px',
                                    fontSize: '12px'
                              }}
                              type="printer" /> Imprimir</div>
                            </Download>
                          </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" href="#">
                        Ações <Icon type="down" />
                        </a>
                    </Dropdown>
                  </center>
              )}
              />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    user: state.user,
    extras: state.customerExtras.data,
    filter: state.customerExtras.filter,
    isLoading: state.customerExtras.isLoading,
    modalInactivate: state.customersnew.modalInactivate,
    findUser: (id) => state.users.filter(r=>r.id===id)[0],
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadExtras: (filter) => dispatch(billsToReceiveActions.loadExtras(filter)),
    setPaid: (id) => dispatch({
      type: 'SET_PAID_EXTRA',
      payload: {
        request:{
          method: 'put',
          url:'/extras/payments/'+id,
        }
      },
      id: id
    }),
    changeStatus: (id, newStatus) => dispatch({
      type: '@@EXTRAS/STATUS',
      payload: {
        request:{
          method: 'put',
          url:'/extras/payments/'+id+'/status',
          data: {newStatus}
        }
      },
      id: id
    }),
    openBillForm: (data) => dispatch({
      type: 'OPEN_BILL_FORM',
      payload: data
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(CustomersList);
import React from 'react';
import { Switch, Form, Row, Divider, Col } from 'antd';

import Tip from '~/components/Tip';

const Services = ({
    form: { getFieldDecorator },
    data
}) => {
    return (
        <Row gutter={16} className="mb-20">
            <Divider orientation="left">Serviços habilitados</Divider>
            <Col span={8}>
                <Form.Item label={<span>Emissão de NF-e <Tip>Se habilitado, o cliente poderá solicitar a emissão de NF-e através do painel do cliente. As solicitações serão listadas no módulo "Solicitações".</Tip></span>}>
                    {getFieldDecorator('allow_nf', {
                        valuePropName: 'checked',
                        initialValue: data.allow_nf
                    })(
                        <Switch />
                    )}
                </Form.Item>
            </Col>
        </Row>
    );
};

export default Services;
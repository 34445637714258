import { Table, Statistic, Card, Row, Col } from 'antd';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import { Layout } from "~/components/UI";
import { 
    ModuleHeaderWrapper,
    ButtonsWrapper,
    ModuleHeader 
} from '~/components/ModuleHeader';
// import DrawerFilter from '~/components/DrawerFilter';

import { toBRL } from '~/components/UI/InputCurrency';

import { tableColumns } from './tableColumns';
import { loadDefaulters } from '../actions';

const Defaulters = () => {
    const dispatch = useDispatch();
    const {
        defaulters: { data: defaulters, isLoading }
    } = useSelector((state) => state.reports);

    // const [showFilterDrawer, setShowFilterDrawer] = useState(false);
    // const [filter, setFilter] = useState({
    //     from: '',
    //     to: ''
    // });

    const columns = tableColumns.getColumns();

    useEffect(() => {
        dispatch(loadDefaulters());
    }, []);

  return (
    <>
        <LoadingTopBar 
            isActive={isLoading} 
        />
        <Layout>
            <div>
                <ModuleHeaderWrapper>
                    <ModuleHeader
                        breadcrumbs={['Relatórios', 'Financeiro', 'Inadimplentes']}
                        title="Inadimplentes"
                    />
                </ModuleHeaderWrapper>
            </div>
            <Row gutter={16}>
                <Col span={8}>
                    <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
                        <Statistic 
                            title="Total" 
                            value={defaulters.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total), 0)}
                            precision={2} 
                            formatter={(v) => 'R$'+toBRL(v)} 
                            valueStyle={{ color: '#f5222d' }}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
                        <Statistic 
                            title="Clientes"
                            value={defaulters.length} 
                            valueStyle={{ color: '#f5222d' }}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
                        <Statistic 
                            title="Parcelas"
                            value={defaulters.reduce((acc, curr) => parseInt(acc) + parseInt(curr.installments), 0)} 
                            valueStyle={{ color: '#f5222d' }}
                            formatter={(v) => parseInt(v)}
                        />
                    </Card>
                </Col>
            </Row>
            <div className={`table-clabs`}>
                <Table 
                    loading={isLoading}
                    tableLayout="fixed"
                    pagination={getDefaultPaginationConfig({
                        storageKey: STORAGE.defaultPageSize.reports
                    })}
                    bordered={true}
                    rowClassName={'row-clabs'}
                    columns={columns}
                    dataSource={defaulters}
                    rowKey="id"
                    // scroll={{ x: columnsWidth, y: `${window.innerHeight-175}px`}}
                />
            </div>
            {/* <DrawerFilter
                onSubmit={(values) => {
                    setFilter(values);
                    setShowFilterDrawer(false);
                    dispatch(loadDefaulters(values));
                }}
                visible={showFilterDrawer}
                setVisible={setShowFilterDrawer}
            /> */}
        </Layout>
    </>
  );
};

export default Defaulters;
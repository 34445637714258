import React, { useEffect } from "react";
import { Button, DatePicker, Form, Drawer, Icon, Select } from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import { loadPayments } from "../actions";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AdvancedFilter = ({
    form: { getFieldDecorator, validateFields },
    // onSubmit,
    visible,
    setVisible,
    initialValues: { filter_by, status, from, to, customer_id }
}) => {

    const dispatch = useDispatch();

    const customers = useSelector((state) => state.customers.list);

    const handleFilter = (e) => {
        e.preventDefault();

        validateFields((err, { period, filter_by, status, customer_id }) => {
            dispatch(loadPayments({
                from: period[0].format("YYYY-MM-DD"),
                to: period[1].format("YYYY-MM-DD"),
                filter_by,
                status,
                customer_id
            }));
            setVisible(false);
        });
    }

    const rangeConfig = {
        initialValue: [moment(from), moment(to)],
        rules: [{ type: 'array', required: true, message: 'Por favor, selecione um período!' }],
    };

    useEffect(() => {
        dispatch(loadPayments({
            from,
            to,
            filter_by,
            status,
            customer_id
        }));
    }, []);

    return (
        <Drawer
            title="Filtro avançado"
            placement="right"
            closable={true}
            onClose={() => setVisible(false)}
            visible={visible}
            width={500}
        >
        <Form
            onSubmit={handleFilter}
        >
            <Form.Item label="Período:">
            {getFieldDecorator('period', rangeConfig)(
                <RangePicker
                    format="DD/MM/YYYY"
                    ranges={{
                        "Hoje": [moment(), moment()],
                        "Últimos 7 dias": [moment().subtract(7, 'days'), moment()],
                        "Últimos 30 dias": [moment().subtract(30, 'days'), moment()],
                        "Este mês": [moment().startOf('month'), moment().endOf('month')],
                        "Mês passado": [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        "Últimos 3 meses": [moment().subtract(3, 'months').startOf('month'), moment()],
                        "Últimos 6 meses": [moment().subtract(6, 'months').startOf('month'), moment()],
                        "Este ano": [moment().startOf('year'), moment().endOf('year')],
                        "Ano passado": [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                        "Ano retrasado": [moment().subtract(2, 'year').startOf('year'), moment().subtract(2, 'year').endOf('year')],
                    }}
                />
            )}
            </Form.Item>
            <Form.Item label="Filtrar por:">
                {getFieldDecorator('filter_by', {
                    initialValue: filter_by,
                })(
                <Select>
                    <Option value="due_at">Vencimento</Option>
                    <Option value="apuration_at">Competência</Option>
                </Select>
                )}
            </Form.Item>
            <Form.Item label="Cliente">
                {getFieldDecorator('customer_id', {
                    initialValue: customer_id,
                })(
                <Select
                    placeholder="Selecione um cliente"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option key={0} value="all">Todos</Option>
                    {customers.map((customer) => (
                    <Option
                        key={customer.id}
                        value={customer.id}
                    >{`${customer.cod} - ${customer.name}`}</Option>
                    ))}
                </Select>
                )}
            </Form.Item>
            <Form.Item label="Status">
                {getFieldDecorator('status', {
                    initialValue: status,
                })(
                <Select>
                    <Option key={1} value="all">Todos</Option>
                    <Option key={2} value="paid">Pago</Option>
                    <Option key={3} value="pendent">Pendente</Option>
                    <Option key={4} value="defaulting">Inadimplente</Option>
                    <Option key={5} value="processed">Ajuizado</Option>
                </Select>
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    <Icon type="search" /> Filtrar
                </Button>
            </Form.Item>
        </Form>
        </Drawer>
    );
};

export default Form.create()(AdvancedFilter);

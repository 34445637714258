import { Breadcrumb, Icon, Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import uid from 'uid';
import styled from "styled-components";

import { Container, StyledBreadcrumb, Title } from "./styles";
import { checkForPermissions } from "../UI/Layout/utils";

export const ModuleHeaderWrapper = styled.div`
    margin: 0px -16px;
    padding: 15px 15px;
    background: #fff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  white-space: nowrap;

  > input {
    max-width: 300px;
  }
`;

export const HeaderRightContent = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  white-space: nowrap;

  > .ant-calendar-picker{
    min-width: 170px;
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const HeaderCenterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ModuleHeader = ({ breadcrumbs, title = null, ...props }) => {
  const { user, users } = useSelector((state) => ({
    user: state.user,
    users: state.users,
  }));

  const isPartner = user.permissions_group_name === "Sócio";

  const activeUsers = users.filter(({ deleted_at, disabled_at }) => !deleted_at && !disabled_at);

  const allowedUsersName =
    isPartner && activeUsers.length > 0
      ? activeUsers
          .filter(({ name, permissions }) => checkForPermissions(permissions) && !!name)
          .map(({ name }) => name)
          .join(", ")
      : "";

  const [firstItem, ...restItems] = breadcrumbs;
  
  return (
    <Container {...props} id="module-header">
      <div>
        <StyledBreadcrumb>
          <Breadcrumb.Item>
            {isPartner && (
              <Tooltip title={allowedUsersName}>
                <Icon type="lock" />
              </Tooltip>
            )}
            <span>{firstItem}</span>
          </Breadcrumb.Item>
          {restItems.map((item) => (
            <Breadcrumb.Item key={uid()}>{item}</Breadcrumb.Item>
          ))}
        </StyledBreadcrumb>
      </div>
      <HeaderBody>
        <HeaderLeftContent>
          <Title>{title || breadcrumbs.slice(-1)}</Title>
          {props.search && props.search}
        </HeaderLeftContent>
        {props.center && (
        <HeaderCenterContent>
          {props.center && props.center}
        </HeaderCenterContent>
        )}
        <HeaderRightContent>
          {props.actions && props.actions}
        </HeaderRightContent>
      </HeaderBody>
      {props.advanced && (
        <HeaderBody>
          {props.advanced && props.advanced}
        </HeaderBody>
      )}
    </Container>
  );
};

import { Card, Spin } from "antd";
import { find, get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { LeadCard } from "../LeadCard";
import { LeadsDrawer } from "../LeadsDrawer";
import * as dashTasksActions from "~/screens/Societario/actions";
import { loadCustomers } from "~/containers/Customer/actions";
import { useDispatch } from "react-redux";
import {
  Board,
  Column,
  ColumnContent,
  Container,
  ExtraBadge,
  ExtraContainer,
  ExtraTotalBadge,
  InfiniteScrollExtraContainer,
  InfiniteScrollStyled,
  LoadingSkeleton,
  LoadingSkeletonContainer,
} from "../styles";
import {
  HideFormLeadsButton,
  HideFormLeadsCount,
  HideFormLeadsTag,
  HideLeadsContainer,
  HideLeadsTitle,
} from "./styles";

export const LeadColumns = ({
  columns,
  moveLead,
  fetchQtddByMonth,
  startShowingConfetti,
  date,
  loadMoreLeads,
  isLoading,
  showDrawer,
  isEighthOnboardingStepActive,
  finishCurrentOnboardingStep,
  onboardingStep,
  user,
  hideLeads
}) => {
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [leadsDrawer, setLeadsDrawer] = useState({
    isVisible: false,
  });

  const loadMoreData = async (column) => {
    if (column.leads.length === column.total) {
      setHasMore(false);
      return;
    }
    await loadMoreLeads({
      columnId: column.id,
      currentCount: column.leads.length,
      moreCount: 50,
    });
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      if (destination.droppableId === 3) {
        startShowingConfetti();
      }

      if (destination.droppableId === 3) {
        finishCurrentOnboardingStep({
          isActive: isEighthOnboardingStepActive,
          step: onboardingStep,
          user
        });
      }

      await moveLead(
        get(find(columns, { id: source.droppableId }), "leads", []),
        get(find(columns, { id: destination.droppableId }), "leads", []),
        source,
        destination,
        result.draggableId
      );

      await fetchQtddByMonth(date);

      if (parseInt(destination.droppableId) === 3) {
        await dispatch(dashTasksActions.loadDashTasks({ showLoading: false }));
        await dispatch(loadCustomers({ showLoading: false }));
      }
        
    }
  };

  const openLeadsDrawer = () => {
    setLeadsDrawer({
      isVisible: true,
    });
  };

  const closeLeadsDrawer = () => {
    setLeadsDrawer({
      isVisible: false,
    });

    fetchQtddByMonth(date);
  };

  const lastCreatedLead = isEighthOnboardingStepActive
    ? [...columns[0].leads]
        .sort((a, b) => moment(b.created_at).diff(a.created_at))
        .at(0)
    : null;

  return (
    <Board>
      <Container
        style={
          isEighthOnboardingStepActive
            ? { zIndex: 999, position: "relative" }
            : undefined
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          {columns.map((column) => (
            <Column key={column.id}>
              <Droppable droppableId={column.id}>
                {(provided) => (
                  <Card
                    title={column.name}
                    bodyStyle={{ padding: 0 }}
                    headStyle={column.style}
                    extra={
                      <ExtraContainer>
                        {column.selectedMonth !== null && (
                          <ExtraBadge
                            count={column.selectedMonth}
                            overflowCount={9999}
                          />
                        )}
                        <ExtraTotalBadge
                          count={column.total}
                          overflowCount={9999}
                        />
                      </ExtraContainer>
                    }
                  >
                    <ColumnContent ref={provided.innerRef}>
                      <InfiniteScrollStyled
                        style={{
                          overflowY: isLoading ? "hidden" : "initial",
                        }}
                        dataLength={column.leads.length}
                        next={() => loadMoreData(column)}
                        hasMore={hasMore}
                        height={window.innerHeight - 380}
                        scrollThreshold={1}
                        loader={
                          <InfiniteScrollExtraContainer>
                            <Spin />
                          </InfiniteScrollExtraContainer>
                        }
                      >
                        {isLoading
                          ? Array(10)
                              .fill(1)
                              .map((_, index) => (
                                <LoadingSkeletonContainer key={index}>
                                  <LoadingSkeleton />
                                </LoadingSkeletonContainer>
                              ))
                          : column.leads
                              .sort((a, b) => {
                                if (column.id !== 1) return 0;
                                return (
                                  a.conversation_started - b.conversation_started
                                );
                              })
                              .map((item, index) => (
                                <LeadCard
                                  key={index}
                                  draggableId={item.id}
                                  index={index}
                                  item={item}
                                  showDrawer={showDrawer}
                                  columnId={column.id}
                                  showOnboarding={
                                    isEighthOnboardingStepActive &&
                                    lastCreatedLead &&
                                    item.id === lastCreatedLead.id
                                  }
                                />
                              ))}
                        {provided.placeholder}
                      </InfiniteScrollStyled>
                    </ColumnContent>
                  </Card>
                )}
              </Droppable>
            </Column>
          ))}
          <HideLeadsContainer>
            <HideLeadsTitle>Ocultos</HideLeadsTitle>
            <HideFormLeadsButton onClick={openLeadsDrawer}>
              <HideFormLeadsTag>Formulário</HideFormLeadsTag>
              <HideFormLeadsCount>{hideLeads.form.length}</HideFormLeadsCount>
            </HideFormLeadsButton>
          </HideLeadsContainer>
        </DragDropContext>
      </Container>
      <LeadsDrawer
        isVisible={leadsDrawer.isVisible}
        onClose={closeLeadsDrawer}
        list={hideLeads.form}
        showDrawer={showDrawer}
      />
    </Board>
  );
};
